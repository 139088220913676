
import sampleInstances from "./ModalInstances";
import {
  defineComponent,
  ref,
  reactive,
  onBeforeUpdate,
  onMounted,
  defineExpose,
} from "vue";

import Panel from "@/components/g4b/panel/Panel.vue";
import G4BButton from "@/components/g4b/button/Button.vue";
import G4BModal from "@/components/g4b/g4bmodal/G4BModal.vue";

export default defineComponent({
  components: {
    G4BButton,
    G4BModal,
    Panel,
  },
  setup(props, context) {
    const modalInstances = reactive(
      sampleInstances
    );

    const modalRefs = ref<typeof G4BModal[]>([]);

    // make sure to reset the refs before each update
    onBeforeUpdate(() => {
      modalRefs.value = [];
    });

    onMounted(() => {
      console.log(modalRefs.value);
      console.log(modalRefs.value[0]);
    });

    defineExpose({ modalRefs });

    return {
      modalInstances,
      modalRefs,
    };
  },
});
