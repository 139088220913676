<template>
  <Multiselect
    v-model="value"
    :options="options"
    :multiple="true"
    :close-on-select="true"
    placeholder="Pick some"
    label="name"
    track-by="name"
    :searchable="true"
  />
</template>

<script>
import { defineComponent, ref } from "vue";
// Vue-multiselect, npm install @vueform/multiselect
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    // multiselect init
    const value = null;
    const options = ref([
      "Batman",
      "Robin",
      "Joker",
    ]);

    return { value, options };
  },
});
</script>

<style
  src="@vueform/multiselect/themes/default.css"
></style>
