
import {
  ref,
  computed,
  defineComponent,
} from "vue";

export default defineComponent({
  setup(props, context) {
    const isDropdownOpen = ref(false);

    function toggleDropdown() {
      isDropdownOpen.value =
        !isDropdownOpen.value;
    }

    const onClickAway = (event: any) => {
      // console.log(event);
      isDropdownOpen.value = false;
    };

    const isSlotDropdownToggle = computed(() => {
      return !!context.slots.toggle;
    });

    return {
      isDropdownOpen,
      // methods
      toggleDropdown,
      // events
      onClickAway,
      // computed
      isSlotDropdownToggle,
    };
  },
});
