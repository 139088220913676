<template>
  <v-select
    :options="books"
    label="title"
    style="width: 100%"
  ></v-select>
</template>

<script>
import { defineComponent, ref } from "vue";
// Vue-select, npm install vue-select@beta;
import vSelect from "vue-select";
//import "vue-select/dist/vue-select.css";

export default defineComponent({
  components: {
    vSelect,
  },
  setup() {
    const books = ref([
      { title: "Old Man's War" },
      { title: "The Lock Artist" },
      { title: "HTML5" },
      { title: "Right Ho Jeeves" },
      { title: "The Code of the Wooster" },
      { title: "Thank You Jeeves" },
    ]);

    return { books };
  },
});
</script>

<style
  src="vue-select/dist/vue-select.css"
></style>
