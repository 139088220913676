
import { defineComponent, ref } from "vue";
import dummyPOSTS from "./dummyPOSTS";
import dummyTODOS from "./dummyTODOS";
import { IConfig } from "@/components/g4b/g4bresult/G4BResult";
import G4BResultDynamic from "@/components/g4b/g4bresult/G4BResult.vue";

export default defineComponent({
  components: {
    G4BResultDynamic,
  },
  setup() {
    const configs: IConfig[] = [];

    function convertDummyPosts(
      originalDummyPosts: any
    ) {
      return originalDummyPosts.map(
        (post: any) => {
          const response: any = Object.entries(
            post
          ).map((propertyName, value) => {
            return [
              propertyName[0],
              {
                label: propertyName[0],
                value: propertyName[1],
              },
            ];
          });
          return {
            [response[0][0]]: response[0][1],
            [response[1][0]]: response[1][1],
            [response[2][0]]: response[2][1],
            [response[3][0]]: response[3][1],
          };
        }
      );
    }

    // sampleConfig1
    const sampleConfig1: IConfig = {
      items: convertDummyPosts(
        dummyPOSTS.slice(0, 10)
      ),
      // If it is differ then 1, you have to use differen generating method, because it is grid inside grid
      default: {
        columns: "1fr",
        autoColumns: "1fr",
        name: "Mobile",
        mode: "grid",
        templateMode: "hide",
        gridItemTemplate: `"id userId" 70px
          "title title" 1fr
          "body body" 1fr / minmax(100px, auto) 1fr`,
      },
      breakpoints: [
        {
          min: 640,
          columns: "1fr 1fr",
          rows: "450px 400px 350px",
          name: "Tablet/Phablet",
          mode: "grid",
          templateMode: "hide",
          gridItemTemplate: `"id userId" 70px
          "title title" 1fr
          "body body" 1fr / minmax(100px, auto) 1fr`,
        },
        {
          min: 1280,
          columns: "2fr 1fr 1fr",
          rows: "400px",
          autoRows: "200px",
          name: "Desktop - Small screens",
          mode: "grid",
          templateMode: "hide",
          gridItemTemplate: `"id title" 1fr
          "body body" 1fr / minmax(100px, auto) 3fr`,
        },
        {
          min: 1600,
          columns: "1fr",
          name: "Desktop - Large screens",
          mode: "table",
          templateMode: "show",
          gridAutoFlow: "column",
          gridItemTemplate: `"id title body body" / 70px 120px 1fr 5fr`,
        },
      ],
    };
    configs.push(sampleConfig1);

    // sampleConfig1
    const sampleConfig2: IConfig = {
      items: convertDummyPosts(
        dummyPOSTS.slice(10, 20)
      ),
      // If it is differ then 1, you have to use differen generating method, because it is grid inside grid
      default: {
        columns: "1fr",
        autoColumns: "1fr",
        name: "Mobile",
        mode: "grid",
        templateMode: "hide",
        gridItemTemplate: `"id title" 70px
          "body body" 1fr / minmax(100px, auto) 1fr`,
      },
      breakpoints: [
        {
          min: 640,
          columns: "1fr 1fr",
          rows: "450px 400px 350px",
          name: "Tablet/Phablet",
          mode: "grid",
          templateMode: "hide",
          gridItemTemplate: `"id userId" 70px
          "title title" 1fr
          "body body" 1fr / minmax(100px, auto) 1fr`,
        },
        {
          min: 1280,
          columns: "1fr 1fr 1fr 1fr",
          rows: "400px",
          autoRows: "200px",
          name: "Desktop - Small screens",
          mode: "grid",
          templateMode: "hide",
          gridItemTemplate: `"id title" 1fr
          "body body" 1fr / minmax(100px, auto) 3fr`,
        },
        {
          min: 1600,
          columns: "1fr",
          name: "Desktop - Large screens",
          mode: "table",
          templateMode: "hide",
          gridAutoFlow: "column",
          gridItemTemplate: `"id title body" / 140px 200px 1fr`,
        },
      ],
    };
    configs.push(sampleConfig2);

    console.log(configs);

    return { configs };
  },
});
