import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "grid-item__content" }
const _hoisted_3 = {
  key: 0,
  class: "label"
}
const _hoisted_4 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StyledGridItem = _resolveComponent("StyledGridItem")!
  const _component_StyledInnerGrid = _resolveComponent("StyledInnerGrid")!
  const _component_StyledOuterGrid = _resolveComponent("StyledOuterGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_StyledOuterGrid, {
      ref: "gridEl",
      class: "grid-theme",
      layoutConfig: _ctx.result.breakPoints
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.items, (gridItem, index) => {
          return (_openBlock(), _createBlock(_component_StyledInnerGrid, {
            class: "inner-grid",
            templateConfig: _ctx.result.templates,
            key: index
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gridItem, (values, name) => {
                return (_openBlock(), _createBlock(_component_StyledGridItem, {
                  key: name,
                  areaName: name,
                  templateConfig: _ctx.result.templates,
                  class: "grid-item"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", null, [
                        (values.label)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(values.label), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(values.value), 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["areaName", "templateConfig"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["templateConfig"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["layoutConfig"])
  ]))
}