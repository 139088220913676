const defaultModalBody = `
                <p>
                  3 Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua.
                  Nunc sed velit dignissim sodales
                  ut eu sem integer vitae. Id
                  aliquet lectus proin nibh nisl
                  condimentum. Fringilla urna
                  porttitor rhoncus dolor purus.
                  Nam aliquam sem et tortor. Nisl
                  vel pretium lectus quam id. Cras
                  pulvinar mattis nunc sed. Quis
                  ipsum suspendisse ultrices
                  gravida dictum fusce ut placerat
                  orci. Tristique magna sit amet
                  purus. Fermentum et sollicitudin
                  ac orci phasellus egestas
                  tellus. Erat pellentesque
                  adipiscing commodo elit at
                  imperdiet dui accumsan. Felis
                  eget nunc lobortis mattis
                  aliquam faucibus. Tincidunt eget
                  nullam non nisi est sit amet
                  facilisis. Mi in nulla posuere
                  sollicitudin aliquam ultrices
                  sagittis orci. Vitae proin
                  sagittis nisl rhoncus mattis
                  rhoncus urna neque. Eget nunc
                  scelerisque viverra mauris in
                  aliquam sem fringilla ut. Nec
                  nam aliquam sem et tortor
                  consequat id. Commodo nulla
                  facilisi nullam vehicula ipsum
                  a. Elementum tempus egestas sed
                  sed. Faucibus purus in massa
                  tempor nec feugiat nisl pretium
                  fusce.
                </p>`;

export default [
  {
    btnText: "modal with title",
    title: "modal title",
    slots: {
      header: `<h1>Custom title</h1>`,
      body: defaultModalBody,
    },
  },
  {
    btnText: "modal with title & subtitle",
    title: "modal title",
    subtitle: "modal subtitle",
    slots: {
      header: `<h1>Custom title</h1>`,
      body: defaultModalBody,
    },
  },
  {
    btnText:
      "modal with title,subtitle,thumbnail",
    title: "modal title",
    subtitle: "modal subtitle",
    thumbnail: require("@/assets/image/recommendation/minigame.png"),
    slots: {
      header: `<h1>Custom title</h1>`,
      body: defaultModalBody,
    },
  },
  {
    btnText: "modal wich is not closeable",
    closeable: false,
    title: "modal title",
    subtitle: "modal subtitle",
    thumbnail: require("@/assets/image/recommendation/minigame.png"),
    slots: {
      header: `<h1>Custom title</h1>`,
      body: defaultModalBody,
    },
  },
  {
    btnText: "modal without backdrop",
    backdrop: false,
    title: "modal title",
    subtitle: "modal subtitle",
    thumbnail: require("@/assets/image/recommendation/minigame.png"),
    slots: {
      header: `<h1>Custom title</h1>`,
      body: defaultModalBody,
    },
  },
  {
    btnText: "modal with custom backdropcolor",
    backdropColor: "#9542f5",
    title: "modal title",
    subtitle: "modal subtitle",
    thumbnail: require("@/assets/image/recommendation/minigame.png"),
    slots: {
      header: `<h1>Custom title</h1>`,
      body: defaultModalBody,
    },
  },
  {
    btnText: "modal with custom width and height",
    backdropColor: "#9542f5",
    title: "modal title",
    subtitle: "modal subtitle",
    thumbnail: require("@/assets/image/recommendation/minigame.png"),
    slots: {
      header: `<h1>Custom title</h1>`,
      body: defaultModalBody,
    },
    maxHeight: "100%",
    maxWidth: "25rem",
  },
  {
    btnText: "modal without footer",
    backdropColor: "#9542f5",
    title: "modal title",
    subtitle: "modal subtitle",
    thumbnail: require("@/assets/image/recommendation/minigame.png"),
    modalWithoutFooter: true,
    slots: {
      header: `<h1>Custom title</h1>`,
      body: defaultModalBody,
    },
  },
];
