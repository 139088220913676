
import { defineComponent, PropType } from "vue";
import { btnType } from "./types/Button";

export default defineComponent({
  name: "G4B-Button",
  props: {
    fill: {
      required: false,
      default: false,
      type: Boolean,
    },
    text: {
      type: String,
      required: true,
    },
    type: {
      required: false,
      default: "default",
      type: String as PropType<btnType>,
    },
  },
  data() {
    return {
      iconOnlyTypes: [
        "defaultIconOnlyButton",
        "iconOnlyButton",
        "iconOnlyButtonInverse",
        "cicledIconOnlyButton",
        "smallCircledIconOnlyButton",
      ],
    };
  },
  computed: {
    showText() {
      return !this.iconOnlyTypes.includes(
        this.type
      );
    },
    classObject() {
      return {
        fill: this.fill,
        default: this.type === "default",
        "icon-only":
          this.type === "defaultIconOnlyButton",
        "icon-only transparent":
          this.type === "iconOnlyButton",
        "icon-only inverse":
          this.type === "iconOnlyButtonInverse",
        "icon-only circled":
          this.type === "cicledIconOnlyButton",
        "icon-only circled small":
          this.type ===
          "smallCircledIconOnlyButton",
      };
    },
  },
});
