<template>
  <div>
    inside parent. Sample property 1 is
    {{ sampleProperty1 }}
  </div>
</template>

<script>
import TooltipParent from "./Tooltip.parent.vue";
import {
  defineComponent,
  ref,
  onMounted,
} from "vue";

export default defineComponent({
  extends: TooltipParent,
  setup() {
    const sampleProperty1 = ref(1);

    onMounted(() => {
      console.log("inside on Mounted 1");
    });

    return { sampleProperty1 };
  },
});
</script>
