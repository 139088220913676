
import { defineComponent } from "vue";
import G4BTooltipParent from "@/components/g4b/g4btooltip/Tooltip.parent.vue";
import G4BTooltip from "@/components/g4b/g4btooltip/Tooltip.wrapper.vue";

export default defineComponent({
  components: {
    G4BTooltipParent,
    G4BTooltip,
  },
});
