
import {
  defineComponent,
  ref,
  computed,
} from "vue";
import Dropdown from "./Dropdown.vue";

export default defineComponent({
  components: {
    Dropdown,
  },
  setup() {
    const showMenu = ref(true);

    function toggleMenu() {
      showMenu.value = !showMenu.value;
    }

    const menuClasses = computed(() => {
      return {
        ...(showMenu.value && {
          "sidenav--opened": showMenu.value,
        }),
      };
    });

    const classBtnSidebar = computed(() => {
      return {
        ...(showMenu.value === false && {
          "sidenav__btn-sidebar--open":
            showMenu.value === false,
        }),
      };
    });

    return {
      showMenu,
      toggleMenu,
      menuClasses,
      classBtnSidebar,
    };
  },
});
