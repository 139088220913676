<template>
  <div>
    valami
    <component
      ref="refTooltip"
      :is="subComponent"
      :initData="items"
      @onSelectChange="handleSelectChange"
    >
      <slot></slot>
    </component>
  </div>
</template>
