<template>
  <div>
    <h2>Notification dialog</h2>
    <G4BModal
      :ref="
        (el) => {
          modalRef = el;
        }
      "
      :showHeader="false"
      :maxWidth="'28rem'"
      class="notification-dialog"
    >
      >
      <template v-slot:body>
        <div class="notification-dialog__body">
          <IconHeader />
          <TextContent />
          <ObjectList
            class="notification-dialog__objectlist"
          />
        </div>
      </template>
    </G4BModal>
    <G4BButton @click="modalBtnClickHandler">
      Open
    </G4BButton>
  </div>
</template>

<script setup>
import { ref } from "vue";

import G4BModal from "@/components/g4b/g4bmodal/G4BModal.vue";
import G4BButton from "@/components/g4b/button/Button.vue";
// import styled parts
import IconHeader from "./parts/icon-header/IconHeader.vue";
import TextContent from "./parts/text-content/TextContent.vue";

import ObjectList from "./parts/object-list/ObjectList.vue";

const modalRef = ref();

const modalBtnClickHandler = (event) => {
  modalRef.value.openModal();
};
</script>

<style lang="scss" scoped>
.notification-dialog {
  &__body {
    position: relative;
    margin-top: 9rem;
  }

  &__objectlist {
    margin-top: 0.4rem;
  }
}
</style>
