import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "slot-wrapper" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showHeader)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["tab-link", {
      'tab-link--active': _ctx.active,
      'tab-link--disabled': _ctx.disabled,
      'tab-link--new': _ctx.isNew,
    }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTab(_ctx.tabIndex)))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "slot-wrapper__content",
              onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showTooltip && _ctx.showTooltip(...args)))
            }, [
              _renderSlot(_ctx.$slots, "header")
            ], 32)
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "content")
        ]))
      : _createCommentVNode("", true)
  ], 64))
}