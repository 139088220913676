import { classItem } from "../G4BMultiselect";

export default class VueSelectConverter {
  static convertItemsToInitOptions(...items) {
    return items.map((item) => {
      return {
        label: item.label,
        code: item.value,
      };
    });
  }

  /*
  static convertItemstoResponse(...items) {
    return items.map(
      (item) =>
        new classItem({
          label: item.label,
          value: item.code,
          disabled: false,
          selected: true,
        })
    );
  }
  */

  static convertItemstoResponse(
    originalValues,
    items
  ) {
    if (!items) return;

    if (!Array.isArray(items)) {
      items = items.code;
    } else {
      items = items.map((item) => item.code);
    }

    const response = originalValues.filter(
      (ogVal) =>
        items.includes(String(ogVal.value))
    );
    response.forEach(
      (filteredItem) =>
        (filteredItem.selected = true)
    );
    return response;
  }

  static convertSelectedItemsToValues(
    multiple,
    ...items
  ) {
    // if (!multiple) return items[0].value;
    if (!multiple) return items[0].value;
    return items.map((item) => {
      return {
        label: item.label,
        code: item.value,
      };
    });
  }
}
