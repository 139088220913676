/* eslint-disable */
export type ItemValue = string | number | boolean;

export interface IItem {
  label: string | number;
  value?: ItemValue;
  disabled?: boolean;
  selected?: boolean;
}

export class classItem implements IItem {
  label;
  value;
  disabled;
  selected;
  constructor({
    label,
    value,
    disabled = false,
    selected = false,
  }: IItem) {
    this.label = label;
    this.value = value
      ? value
      : String(label).trim().replace(/ /g, "_");
    this.disabled = disabled ? disabled : false;
    this.selected = selected ? selected : false;
  }
}
// type Item = string | number;
export type Items = IItem[];
//type Items = any;

interface IOptions {
  multiple?: boolean;
  disabled?: boolean;
  items?: Items;
}

export interface IG4BMultiselect {
  disabled: boolean;
  multiple: boolean;
  // placeholderText
  getPlaceholder(): string;
  setPlaceholder(placeHoldText: string): void;
  // value methods
  getItems(): Items;
  addItem(item: IItem): void;
  addItems(items: Items): void;
  getSelectedItems(): Items;
  selectItem(value: IItem): void;
  selectItems(values: Items): void;
  updateSelectedItems(values: Items): void;
  removeItem(value: IItem): void;
  // disable
  getDisable(): boolean;
  setDisable(value: boolean): void;
  // searchable
  getSearchable(): boolean;
  setSearchable(value: boolean): void;
  // search
  //search( (element, index, array): string | number );
  search(
    callbackFn: (
      element: ItemValue,
      index?: number,
      array?: Items
    ) => IItem
  ): IItem | void;
}

export class G4BMultiselect
  implements IG4BMultiselect
{
  // public properties
  disabled: boolean = false;
  multiple: boolean = false;
  // private properties
  private items: Items = Array();
  private placeholderText: string = "";
  private disable: boolean = false;
  private searchAble: boolean = false;

  constructor({
    multiple = false,
    disabled = false,
    items = [],
  }: IOptions) {
    this.multiple = multiple;
    this.disabled = disabled;
    this.items = items.map(
      (item) => new classItem(item)
    );
  }

  // Items functions
  getItems(): Items {
    return this.items.map((item) => {
      return { ...item };
    });
  }

  addItem(item: IItem): void {
    this.items.push(new classItem(item));
  }

  addItems(items: Items): void {
    this.items = [...this.items, ...items].map(
      (item) => new classItem(item)
    );
  }

  getSelectedItems(): Items {
    return this.items.filter(
      (item) => item.selected === true
    );
    // return this.values.slice();
  }

  private findItem(
    searchItem: number | string | IItem
  ): IItem | undefined {
    return (
      // search by item match
      this.items.find(
        (item) => item === searchItem
      ) ||
      // search by value match
      this.items.find(
        (item) => item.value === searchItem
      ) ||
      // searcg by label match
      this.items.find(
        (item) => item.label === searchItem
      )
    );
  }

  selectItem(
    selectedItem: number | string | IItem
  ): void {
    const newlySelectedItem =
      this.findItem(selectedItem);
    if (!newlySelectedItem) return;
    if (!this.multiple) {
      this.items.forEach(
        (item) => (item.selected = false)
      );
    }
    newlySelectedItem.selected = true;
  }
  selectItems(values: Items): void {
    values.forEach((value) => {
      const searchedItem = this.items.find(
        (item) =>
          item.label === value.label &&
          item.value === value.value
      );

      if (!searchedItem) return;
      searchedItem.selected = true;
    });
  }

  updateSelectedItems(values: Items): void {
    if (!values || values.length === 0) {
      this.items.forEach(
        (item) => (item.selected = false)
      );
      return;
    }

    values.forEach((value) => {
      this.items.find(
        (item) => item.value === value.value
      )!.selected = true;
    });

    this.items
      .filter(
        (item) =>
          !values
            .map((value) => value.value)
            .includes(item.value)
      )
      .forEach(
        (filteredItem) =>
          (filteredItem.selected = false)
      );
  }

  removeItem(value: IItem): void {
    console.log("TODO removeitem");
  }

  // Placeholder functions
  getPlaceholder(): string {
    return this.placeholderText;
  }

  setPlaceholder(placeHoldText: string) {
    this.placeholderText = placeHoldText;
  }

  // disable function
  getDisable(): boolean {
    console.log("TODO megcsinálni");
    return this.disable;
  }

  setDisable(value: boolean): void {
    this.disable = value;
  }

  // searchable
  getSearchable(): boolean {
    console.log("TODO search item");
    return this.searchAble;
  }

  setSearchable(value: boolean): void {
    this.searchAble = value;
  }

  // search
  search(
    callbackFn: (
      element: ItemValue,
      index: number,
      array: Items
    ) => IItem
  ): IItem | void {
    /*
    return this.items.find(callbackFn);
    */
  }
}
/* eslint-enable */
