
import { defineComponent, ref, watch } from "vue";
// Vue-multiselect, npm install @vueform/multiselect
import Multiselect from "@vueform/multiselect";
import VueStaticConverter from "./VueSelect.converter";

export default defineComponent({
  components: {
    Multiselect,
  },
  props: {
    initData: {
      required: true,
      type: Object,
    },
  },
  emits: ["onSelectChange"],
  setup(props, context) {
    const selectDisabled = ref(
      props.initData.disabled
    );
    // multiselect init
    const selectMode = ref(
      //props.initData.multiple ? "multiple" : ""
      props.initData.multiple ? "tags" : "single"
    );
    // init default selected values
    const modelSelectedItems = ref(
      VueStaticConverter.convertSelectedItemsToValues(
        props.initData.multiple,
        ...props.initData.getSelectedItems()
      )
    );
    // init select options
    const selectOptions = ref(
      VueStaticConverter.convertItemsToInitOptions(
        ...props.initData.getItems()
      )
    );

    const handleClear = () => {
      context.emit("onSelectChange", null);
    };

    watch(modelSelectedItems, (newValue) => {
      if (!newValue) return;
      context.emit(
        "onSelectChange",
        VueStaticConverter.convertItemstoResponse(
          props.initData.getItems(),
          newValue
        )
      );
    });

    return {
      modelSelectedItems,
      selectOptions,
      selectMode,
      selectDisabled,
      // function
      handleClear,
    };
  },
});
