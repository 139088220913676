
import {
  defineComponent,
  ref,
  PropType,
  onMounted,
} from "vue";
import {
  Items,
  IG4BMultiselect,
} from "./G4BMultiselect";
// import select2 componenents
import VueSelect from "./vue-select/VueSelect.vue";
import VueMultiselect from "./vue-multiselect/VueMultiselect.vue";

export default defineComponent({
  components: {
    VueSelect,
    VueMultiselect,
  },
  props: {
    items: {
      type: Object as PropType<IG4BMultiselect>,
      required: true,
    },
    subComponent: {
      type: String,
      required: false,
      default: "VueSelect",
    },
  },
  emits: ["onSelectChange"],
  setup(props, context) {
    const refSelect2 = ref<typeof VueSelect>();
    // functions
    // --------- START: EVENT HANDLERS --------- //
    const handleSelectChange = (
      response: Items
    ) => {
      /*
      console.log(
        "inside handleSelectChange in wrapper"
      );
      */
      props.items.updateSelectedItems(response);
      context.emit("onSelectChange", response);
    };
    // --------- END: EVENT HANDLERS --------- //

    onMounted(() => {
      // the DOM element will be assigned to the ref after initial render
      //console.log(refSelect2.value); // <div>This is a root element</div>
      if (refSelect2.value) {
        //console.log(refSelect2.value);
      }
    });
    return {
      refSelect2,
      handleSelectChange,
      // methods
    };
  },
});
