import "normalize.css";
import { createApp } from "vue";
import App from "./App.vue";
import VueClickAway from "vue3-click-away";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// import directives
import tooltip from "@/directives/g4b-tooltip/Tooltip.vue";
// import global components
import ComponentItem from "@/components/componentItem/ComponentItem.vue";
import G4BTourPlugin from "@/plugins/tour-plugin/g4b-tour-plugin";

// START:TODO törölni
import "shepherd.js/dist/css/shepherd.css";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueClickAway)
  .use(G4BTourPlugin);

app.directive("g4b-tooltip", tooltip);

app.component("ComponentItem", ComponentItem);

app.mount("#app");
