<template>
  <styled-outer-grid
    :defaults="layoutConfig.default"
    :breakPoints="layoutConfig.breakpoints"
    ><slot></slot
  ></styled-outer-grid>
</template>

<script>
import StyledOuterGrid from "./StyledOuterGrid";
export default {
  components: {
    StyledOuterGrid,
  },
  props: {
    layoutConfig: {
      type: Object,
      required: true,
      // lehet kéne valami default object
      // default: "VueSelect",
    },
  },
};
</script>
