<template>
  <a
    href="#"
    class="dropdown__menu__item"
    @click.prevent="click"
    ><slot></slot
  ></a>
</template>

<style lang="scss">
.dropdown__menu__item {
  display: block;
  //
  background-color: red;
  margin: 10px;
  padding: 10px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
