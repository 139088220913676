
import { defineComponent } from "vue";

import "@egjs/vue3-flicking/dist/flicking.css";

import Flicking from "@egjs/vue3-flicking";

export default defineComponent({
  components: {
    Flicking,
  },
});
