import styled from "vue3-styled-components";

const gridProps = {
  isReward: Boolean,
};

const StyledSummary = styled("div", gridProps)`
  color: ${(props) =>
    props.isReward ? "#2bc191" : "#ffa000"};
`;

export default StyledSummary;
