
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    contentStyle: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      slotKey: 0,
    };
  },
  methods: {
    reloadSlot() {
      // manually trigger rerender
      this.slotKey++;
      this.rotateIcon();
    },
    rotateIcon() {
      // rotateIcon
      const icon = this.$refs
        .rotateSpinner as InstanceType<
        typeof HTMLElement
      >;
      icon.classList.add("rotate");

      icon.onanimationend = () => {
        icon.classList.remove("rotate");
      };
    },
  },
});
