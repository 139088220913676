<template>
  <h1>Carousels</h1>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Carousels",
  components: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
