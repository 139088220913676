
// import stuffs from vue
import {
  ref,
  computed,
  defineComponent,
  watch,
  PropType,
} from "vue";
// import types
import { DropdownDirections } from "./G4BDropdown";

export default defineComponent({
  props: {
    direction: {
      type: String as PropType<DropdownDirections>,
      required: false,
      default: "bottom-left", // bottom-left, bottom-right, top-left, top-right
    },
    zIndex: {
      type: Number,
      required: false,
      default: undefined,
      /*
      validator(value) {
        return Number.isInteger(value);
      },
      */
    },
  },
  setup(props, context) {
    const isDropdownOpen = ref(false);
    const refToggleWrapper = ref<HTMLElement>();
    const refDropdownScroller =
      ref<HTMLElement>();

    function toggleDropdown() {
      isDropdownOpen.value =
        !isDropdownOpen.value;
    }

    function setScrollerMaxHeight() {
      const dropdownDomRect: DOMRect =
        refToggleWrapper.value!.getBoundingClientRect();
      if (
        dropdownDomRect &&
        refDropdownScroller.value
      ) {
        let distance;
        const paddingTopBottom =
          Number.parseFloat(
            getComputedStyle(
              refDropdownScroller.value!
                .parentElement!
            ).paddingTop
          ) +
          Number.parseFloat(
            getComputedStyle(
              refDropdownScroller.value!
                .parentElement!
            ).paddingBottom
          );
        if (props.direction.includes("top")) {
          distance =
            window.pageYOffset +
            dropdownDomRect.top;
        } else {
          distance =
            window.innerHeight -
            dropdownDomRect.bottom;
        }

        refDropdownScroller.value!.style.maxHeight =
          distance - paddingTopBottom + "px";
      }
    }

    const onClickAway = (event: any) => {
      // console.log(event);
      isDropdownOpen.value = false;
    };

    const isSlotDropdownToggle = computed(() => {
      return !!context.slots.toggle;
    });

    /*** START:CLASS HELPERS ***/
    const classDropdownDirection = computed(
      () => {
        return props.direction;
      }
    );

    const classToggleWrapper = computed(() =>
      isDropdownOpen.value
        ? `dropdown__toggle-wrapper--${props.direction}`
        : null
    );
    /*** END:CLASS HELPERS */

    /* START:HANDLE CUSTOM Z-INDEXES */
    const cssVariablesFromJS = computed(() => {
      return {
        ...(props.zIndex && {
          "--g4bdropdown-z-index": props.zIndex,
        }),
      };
    });
    /* END:HANDLE CUSTOM Z-INDEXES */

    watch(isDropdownOpen, () => {
      setScrollerMaxHeight();
    });

    return {
      isDropdownOpen,
      // methods
      toggleDropdown,
      // events
      onClickAway,
      // computed
      isSlotDropdownToggle,
      cssVariablesFromJS,
      // class helpers
      classDropdownDirection,
      classToggleWrapper,
      // refs
      refToggleWrapper,
      refDropdownScroller,
    };
  },
});
