<template>
  <StyledGridItem
    :class="'grid-area-' + areaName"
    :areaName="areaName"
    :defaults="templateConfig.default"
    :breakPoints="templateConfig.breakpoints"
  >
    <slot></slot>
  </StyledGridItem>
</template>

<script>
import StyledGridItem from "./StyledGridItem";

export default {
  components: {
    StyledGridItem,
  },
  props: {
    templateConfig: {
      type: Object,
      required: true,
      // lehet kéne valami default object
      // default: "VueSelect",
    },
    areaName: {
      type: String,
      required: true,
      // lehet kéne valami default object
      // default: "VueSelect",
    },
  },
};
</script>
