<template>
  <div class="component-list">
    <div class="header-section">
      <h2>Dropdown Examples</h2>
    </div>
    <div class="content-section">
      <!-- START:DROPDOWN -->
      <ComponentItem
        title="Dropdown"
        style="width: 100%"
        contentStyle="flex-direction: row; flex-wrap:wrap;"
      >
        <Dropdown />

        <Dropdown />

        <Dropdown />

        <Dropdown />

        <!-- START:ROW -->
        <div class="row">
          <G4BDropdown
            ref="sampleDropdown10"
            direction="bottom-left"
          >
            <template v-slot:toggle>
              <G4BButton
                @click="
                  $refs.sampleDropdown10.toggleDropdown()
                "
                text="Toggle Modal"
                type="cicledIconOnlyButton"
              >
                <!-- prettier-ignore -->
                <img src="@/assets/image/Theme/image/notification.png" >
              </G4BButton>
            </template>
            <template v-slot:menuItems>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
            </template>
          </G4BDropdown>

          <G4BDropdown
            ref="sampleDropdown11"
            direction="bottom-right"
          >
            <template v-slot:toggle>
              <G4BButton
                @click="
                  $refs.sampleDropdown11.toggleDropdown()
                "
                text="Toggle Modal"
                type="cicledIconOnlyButton"
              >
                <!-- prettier-ignore -->
                <img src="@/assets/image/Theme/image/notification.png"
          >
              </G4BButton>
            </template>
            <template v-slot:menuItems>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
            </template>
          </G4BDropdown>

          <G4BDropdown
            ref="sampleDropdown12"
            direction="top-left"
          >
            <template v-slot:toggle>
              <G4BButton
                @click="
                  $refs.sampleDropdown12.toggleDropdown()
                "
                text="Toggle Modal"
                type="cicledIconOnlyButton"
              >
                <!-- prettier-ignore -->
                <img src="@/assets/image/Theme/image/notification.png"
          >
              </G4BButton>
            </template>
            <template v-slot:menuItems>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
            </template>
          </G4BDropdown>

          <G4BDropdown
            ref="sampleDropdown13"
            direction="top-right"
          >
            <template v-slot:toggle>
              <G4BButton
                @click="
                  $refs.sampleDropdown13.toggleDropdown()
                "
                text="Toggle Modal"
                type="cicledIconOnlyButton"
              >
                <!-- prettier-ignore -->
                <img src="@/assets/image/Theme/image/notification.png"
          >
              </G4BButton>
            </template>
            <template v-slot:menuItems>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
            </template>
          </G4BDropdown>

          <G4BDropdown ref="sampleDropdown3">
            <template v-slot:toggle>
              <!-- prettier-ignore -->
              <img
            @click="
                $refs.sampleDropdown3.toggleDropdown()
              "
            src="@/assets/image/Theme/image/notification.png"
          >
            </template>
          </G4BDropdown>

          <G4BDropdown ref="sampleDropdown4">
            <template v-slot:toggle>
              <G4BButton
                @click="
                  $refs.sampleDropdown4.toggleDropdown()
                "
                text="Toggle Modal"
                type="cicledIconOnlyButton"
              >
                <!-- prettier-ignore -->
                <img src="@/assets/image/Theme/image/notification.png"
          >
              </G4BButton>
            </template>
          </G4BDropdown>

          <G4BDropdown
            ref="sampleDropdown5"
            :zIndex="15"
          >
            <template v-slot:toggle>
              <G4BButton
                @click="
                  $refs.sampleDropdown5.toggleDropdown()
                "
                text="Toggle Modal"
                type="cicledIconOnlyButton"
              >
                <!-- prettier-ignore -->
                <img src="@/assets/image/Theme/image/notification.png">
              </G4BButton>
            </template>
            <template v-slot:menuItems>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item1
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item2
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item3
              </G4bDropdownMenuItem>
              <G4bDropdownMenuItem>
                item4
              </G4bDropdownMenuItem>
            </template>
          </G4BDropdown>
        </div>
        <!-- END:ROW -->
      </ComponentItem>
      <!-- END:DROPDOWN -->
    </div>
  </div>
</template>

<script>
import { defineComponent, createApp } from "vue";
// custom components
import G4BButton from "@/components/g4b/button/Button.vue";
import {
  defaultProgressBarConfig,
  customProgressBarConfig,
} from "@/components/g4b/card/Card";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import G4BDropdown from "@/components/g4b/g4bdropdown/G4BDropdown.vue";
import G4bDropdownMenuItem from "@/components/g4b/g4bdropdown/dropdown-menu-item/DropdownMenuItem.vue";
// import images
// import imgGameController from "@/assets/image/recommendationc/minigame.png";

export default {
  name: "ComponentsList",
  components: {
    G4BButton,
    Dropdown,
    G4BDropdown,
    G4bDropdownMenuItem,
  },
};
</script>

<style lang="scss" scoped>
.component-list {
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px
    0px;
  box-sizing: border-box;
  padding: 10px 20px;
}

.content-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  flex-wrap: wrap;

  gap: 1rem;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
