
import {
  defineComponent,
  ref,
  inject,
  onMounted,
  nextTick,
} from "vue";
import { IG4BTour } from "@/plugins/tour-plugin/types";
import Menu from "./components/menu/Menu.vue";

export default defineComponent({
  name: "App",
  components: {
    Menu,
  },
  setup(porps, context) {
    /*
    const g4btour: any = inject("g4btour");
    const tour: any = ref();

    onMounted(() => {
      nextTick(() => {
        //
        tour.value = g4btour({
          useModalOverlay: true,
        });

        tour.value.addStep({
          attachTo: {
            element: "#testing-shepherd",
            on: "top",
          },
          text: "Test",
        });

        tour.value.addStep({
          attachTo: {
            element: "#testing-shepherd-2",
            on: "bottom",
          },
          text: "Test2",
        });

        tour.value.addStep({
          attachTo: {
            element: "#testing-shepherd-3",
            on: "right",
          },
          title: "Creating a Shepherd Tour",
          text: `Creating a Shepherd tour is easy. too!\
  Just create a \`Tour\` instance, and add as many steps as you want.`,
          buttons: [
            {
              action(): any {
                return tour.value.moveToPrevStep();
              },
              classes:
                "shepherd-button-secondary",
              text: "Back",
            },
            {
              action(): any {
                return tour.value.moveToNextStep();
              },
              text: "Next",
            },
          ],
          id: "creating",
        });

        tour.value.start();

        //
      });
    });

    return {
      tour,
    };
    */
  },
});
