<template>
  <div>
    <h2>Content package</h2>
    <G4BModal
      :ref="
        (el) => {
          modalRef = el;
        }
      "
      :showHeader="true"
      :title="'Content package title'"
      :width="'calc(100% - 1.6rem)'"
      :maxWidth="'32rem'"
    >
      <template v-slot:body>
        <Panel>
          <TabContent
            >Itt látod a témakörhöz kapcsolódó
            feladataidat. A tartalmakat a
            panelekre kattintva érheted
            el.</TabContent
          >
          <div class="card-container">
            <Card
              bgColor="#ffa000"
              :title="'Kvízek tudástár'"
              :thumbnail="
                require('@/assets/image/recommendation/minigame.png')
              "
              action="A témakör feladatainak listája"
            >
              <template v-slot:action
                ><i class="FontIcon Icon-List"></i
              ></template>
            </Card>
            <Card
              :title="'Tevékenységek'"
              :thumbnail="
                require('@/assets/image/recommendation/minigame.png')
              "
              action="A témakör feladatainak listája"
            >
              <template v-slot:action
                ><i class="FontIcon Icon-List"></i
              ></template>
            </Card>
          </div>
        </Panel>
      </template>

      <template v-slot:footer>
        <G4BButton
          :fill="true"
          @click="modalRef.closeModal()"
          >bezárás</G4BButton
        >
      </template>
    </G4BModal>
    <G4BButton
      type="button"
      @click="modalBtnClickHandler"
    >
      Open
    </G4BButton>
  </div>
</template>

<script setup>
import { ref } from "vue";
import G4BModal from "@/components/g4b/g4bmodal/G4BModal.vue";

import Panel from "@/components/g4b/panel/Panel.vue";
import TabContent from "@/components/g4b/g4btabs/tab/content/Content";
import Card from "@/components/g4b/card/Card.vue";
import G4BButton from "@/components/g4b/button/Button.vue";
// import modal contents
const modalRef = ref();

const modalBtnClickHandler = (event) => {
  modalRef.value.openModal();
};
</script>

<style lang="scss" scoped>
.card-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1.6rem;
}
</style>
