<template>
  <div class="wrapper">
    <div class="background">
      <div class="foreground">
        <img
          class="image"
          src="https://newdesign.g4bdev.eu/file/object/image-510-1-normal.png"
          alt="Placeholder text"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: -4.4rem;
  width: 8.8rem;
  height: 8.8rem;
  left: 50%;
  transform: translateX(-50%);
}

.background {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    #f9cb4b 0%,
    #ffa000 100%
  );
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0.2rem;

  &::before {
    position: absolute;
    content: "";
    width: 24rem;
    height: 24rem;
    z-index: -1;
    background-image: url("https://newdesign.g4bdev.eu/game/style/SimpleGame/image/notification-thumbnail-decoration-1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::after {
    position: absolute;
    z-index: -1;
    content: "";
    width: 24rem;
    height: 24rem;
    background-image: url("https://newdesign.g4bdev.eu/game/style/SimpleGame/image/notification-thumbnail-decoration-2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.foreground {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #567eb3;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.image {
  width: 100%;
}
</style>
