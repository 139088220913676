import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "thumbnail" }
const _hoisted_2 = { class: "thumbnail__image-container" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "thumbnail__image",
        src: _ctx.imageURL,
        alt: _ctx.alt
      }, null, 8, _hoisted_3)
    ])
  ]))
}