
import {
  defineComponent,
  ref,
  PropType,
} from "vue";
import {
  G4BResultCSSConverter,
  IConfig,
} from "./G4BResult";
// import components
import StyledOuterGrid from "./styled-outer-grid/StyledOuterGrid.vue";
import StyledInnerGrid from "./styled-inner-grid/StyledInnerGrid.vue";
import StyledGridItem from "./styled-grid-item/StyledGridItem.vue";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IConfig>,
      required: true,
    },
  },
  components: {
    StyledOuterGrid,
    StyledInnerGrid,
    StyledGridItem,
  },
  setup(props) {
    const gridEl = ref<HTMLDivElement>();

    const result = new G4BResultCSSConverter(
      props.config
    );

    //console.log(result.templates);
    // console.log(result.breakPoints);
    // console.log(result.templates);
    // console.log(result.items);

    return {
      // refs
      gridEl,
      result,
    };
  },
});
