<template>
  Vue Select Adapter component
  <v-select
    style="width: 100%"
    :options="selectOptions"
    :multiple="selectModeMultiple"
    v-model="modelSelectedItems"
    placeholder="Pick some"
    :disabled="selectDisabled"
  ></v-select>
</template>

<script>
/* Cant use this third party lib with TypesScript because it doesn't have ts support */
import { defineComponent, ref, watch } from "vue";
// Vue-select, npm install vue-select@beta;
import vSelect from "vue-select";
import VueStaticConverter from "./VueSelect.converter";

export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    initData: {
      required: true,
      type: Object,
    },
  },
  emits: ["onSelectChange"],
  setup(props, context) {
    const selectDisabled = ref(
      props.initData.disabled
    );

    const modelSelectedItems = ref(
      VueStaticConverter.convertSelectedItemsToValues(
        props.initData.multiple,
        ...props.initData.getSelectedItems()
      )
    );

    const selectModeMultiple = ref(
      props.initData.multiple
    );
    // convert items from G4BMultiselect fromat to vue-select format
    const selectOptions = ref([]);
    selectOptions.value =
      VueStaticConverter.convertItemsToInitOptions(
        ...props.initData.getItems()
      );

    // watchers
    // watch selected value changing
    watch(modelSelectedItems, (newValue) => {
      context.emit(
        "onSelectChange",
        VueStaticConverter.convertItemstoResponse(
          props.initData.getItems(),
          newValue
        )
      );
    });

    return {
      selectOptions,
      selectModeMultiple,
      modelSelectedItems,
      selectDisabled,
    };
  },
});
</script>
