
// egymásba nyiló modalok
// teleport
import {
  defineComponent,
  ref,
  computed,
} from "vue";
import G4BButton from "@/components/g4b/button/Button.vue";

export default defineComponent({
  components: {
    G4BButton,
  },
  props: {
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    headerColor: {
      type: String,
      required: false,
      default: undefined,
    },
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    subtitle: {
      type: String,
      required: false,
      default: undefined,
    },
    thumbnail: {
      type: String,
      required: false,
      default: undefined,
    },
    closeable: {
      type: Boolean,
      required: false,
      default: true,
    },
    backdrop: {
      type: Boolean,
      required: false,
      default: true,
    },
    backdropColor: {
      type: String,
      required: false,
      default: undefined,
    },
    width: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    maxWidth: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    maxHeight: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
  },
  setup(props, context) {
    const modalTitle = ref("Sample title");
    const show = ref(false);

    // methods
    function closeModal() {
      if (props.closeable) {
        show.value = false;

        document!
          .querySelector("body")!
          .classList!.remove("overflow-hidden")!;
      }
    }
    function openModal() {
      show.value = true;
      document!
        .querySelector("body")!
        .classList.add("overflow-hidden")!;
    }

    // the slots has any inner element or not
    // console.log(!!context.slots.header);
    // computed props
    const isSlotHeader = computed(() => {
      return !!context.slots.header;
    });

    const isSlotFooter = computed(() => {
      return !!context.slots.footer;
    });

    const showTitle = computed(() => {
      return (
        props.title &&
        props.title.length > 0 &&
        !context.slots.header
      );
    });

    const showSubtitle = computed(() => {
      return (
        props.subtitle &&
        props.subtitle.length > 0 &&
        !context.slots.header
      );
    });

    const showThumbnail = computed(() => {
      return (
        props.thumbnail && !context.slots.header
      );
    });

    const showLayout = computed(() => {
      return (
        !!showTitle.value ||
        !!showSubtitle.value ||
        !!showThumbnail.value
      );
    });

    const modalStyle = computed(() => {
      return {
        ...(props.width && {
          width: props.width,
        }),
        ...(props.maxWidth && {
          "max-width": props.maxWidth,
        }),
        ...(props.maxHeight && {
          "max-height": props.maxHeight,
        }),
      };
    });

    return {
      modalTitle,
      show,
      // methods
      closeModal,
      openModal,
      // computed properties
      isSlotHeader,
      isSlotFooter,
      showTitle,
      showSubtitle,
      showThumbnail,
      showLayout,
      modalStyle,
    };
  },
});
