import {
  IItem,
  Items,
  classItem,
  ItemValue,
} from "../G4BMultiselect";

interface MultiselectItem {
  label: number | string;
  value: ItemValue | undefined;
  disabled: boolean;
}

type MultiselectItems = MultiselectItem[];

export default class VueSelectConverter {
  static convertItemsToInitOptions(
    ...items: Items
  ): MultiselectItems {
    return items.map((item) => {
      return {
        value: item.value,
        label: item.label,
        disabled: item.disabled
          ? item.disabled
          : false,
      };
    });
  }

  static convertItemstoResponse(
    originalValues: Items,
    items: any
  ): Items {
    const response: Items | [] =
      originalValues.filter((ogVal) =>
        items.includes(String(ogVal.value))
      );
    response.forEach(
      (filteredItem) =>
        (filteredItem.selected = true)
    );
    return response;
  }

  static convertSelectedItemsToValues(
    multiple: boolean,
    ...items: Items
  ) {
    if (!multiple) return items[0].value;
    return items.map((item) => item.value);
  }
}
