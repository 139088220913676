
import { defineComponent, ref } from "vue";

import {
  G4BMultiselect as Multiselect,
  Items as MultiselectOptions,
} from "@/components/g4b/g4bmultiselect/G4BMultiselect";
// import select2 componenents
import G4BMultiselect from "@/components/g4b/g4bmultiselect/G4BMultiselect.wrapper.vue";

export default defineComponent({
  components: {
    G4BMultiselect,
  },
  props: {
    subComponent: {
      type: String,
      required: false,
      default: "VueSelect",
    },
  },
  setup() {
    const selectConfig = new Multiselect({
      multiple: true,
      // disabled: true,
      items: [
        {
          label: "kutya",
          value: "kiskutya",
        },
        { label: "macska" },
        { label: "papagáj" },
        { label: "viziló" },
        { label: "póni" },
        { label: "zebra" },
      ],
    });

    // Adding extra item
    selectConfig.addItem({
      label: "oroszlán",
      value: "oroszlan_2",
      selected: true,
    });

    const refSelect2 =
      ref<typeof G4BMultiselect>();
    const multiselectConfig = ref(selectConfig);
    const selectedItems = ref<MultiselectOptions>(
      selectConfig.getSelectedItems()
    );
    const key = ref(0);
    // Text value for showing newly added values
    const msg = ref("");

    // --------- START: EVENT HANDLERS --------- //
    const handleSelectChange = (
      response: MultiselectOptions
    ) => {
      /*
      console.log("inside G4BMultiselect.vue");
      console.log(response);

      if (response.length > 0) {
        msg.value = `Új elemet válaszottál ki ${
          response[response.length - 1].label
        }`;
      }
      */

      selectedItems.value =
        multiselectConfig.value.getSelectedItems();
    };

    const addItem = () => {
      multiselectConfig.value.addItem({
        label: "Gizmó",
      });
      /*
      console.log(
        multiselectConfig.value.getItems()
      );
      */
      key.value++;
    };

    const disableSelect = () => {
      multiselectConfig.value.disabled =
        !multiselectConfig.value.disabled;
      key.value++;
    };

    // --------- END: EVENT HANDLERS --------- //

    return {
      multiselectConfig,
      selectedItems,
      // helpers
      msg,
      key,
      // handlers
      handleSelectChange,
      addItem,
      disableSelect,
      // refs
      refSelect2,
    };
  },
});
