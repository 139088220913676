import styled from "vue3-styled-components";

const gridProps = {};

const StyledTitle = styled("div", gridProps)`
  font-family: "Roboto Condensed";
  color: #2bc191;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 0.25em;
`;

export default StyledTitle;
