<template>
  <styled-inner-grid
    :defaults="templateConfig.default"
    :breakPoints="templateConfig.breakpoints"
    ><slot></slot
  ></styled-inner-grid>
</template>

<script>
import StyledInnerGrid from "./StyledInnerGrid";

export default {
  components: {
    StyledInnerGrid,
  },
  props: {
    templateConfig: {
      type: Object,
      required: true,
      // lehet kéne valami default object
      // default: "VueSelect",
    },
  },
};
</script>
