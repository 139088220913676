
import { defineComponent } from "vue";
import VueSelect from "./VueSelect.vue";
import VueMultiselect from "./VueMultiselect.vue";
import G4BMultiselect from "./G4BMultiselect.vue";

export default defineComponent({
  components: {
    VueSelect,
    VueMultiselect,
    "g4b-multiselect": G4BMultiselect,
  },
  setup() {
    const selectOptions = {
      label: "Sample label",
    };
    return { selectOptions };
  },
});
