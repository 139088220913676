import styled from "vue3-styled-components";

const gridProps = {
  defaults: Object,
  breakPoints: Array,
};

const generateTemplate = (mode, template) =>
  `grid-template: ${
    mode === "show" ? "unset" : template
  };`;

const generateAutoflow = (flow) =>
  `grid-auto-flow: ${flow};`;

const StyledInnerGrid = styled("div", gridProps)`
  display: grid;
  box-sizing: border-box;

  ${(props) =>
    generateTemplate(
      props.defaults.mode,
      props.defaults.template
    )}

  ${(props) =>
    props.defaults.gridAutoFlow &&
    generateAutoflow(props.defaults.gridAutoFlow)}

  ${(props) =>
    props.breakPoints.map((breakpont) => {
      return `@media only screen and (min-width: ${
        breakpont.min
      }px) {

        ${generateTemplate(
          breakpont.mode,
          breakpont.template
        )}

        ${
          breakpont.gridAutoFlow &&
          generateAutoflow(breakpont.gridAutoFlow)
        }

      }`;
    })}
`;

export default StyledInnerGrid;
