<template>
  <div class="component-list">
    <div class="header-section">
      <h2>Components list</h2>
    </div>
    <div class="content-section">
      <ComponentItem
        title="Buttons"
        style="min-width: 50rem"
      >
        <!-- START:BUTTON DEFAULT -->
        <h4>Start Buttons Default</h4>

        <G4BButton
          text="Gomb Felirat"
        ></G4BButton>

        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
          "
        >
          <G4BButton text="három" />
          <G4BButton text="egy" />
          <G4BButton text="sorban" />
        </div>

        <G4BButton
          text="Ha a felirat, hosszabb mint a min-width"
        />

        <G4BButton
          text="fix méret: 19rem"
          style="width: 19rem"
        />

        <G4BButton
          text="fix méret de sok a duma duma duma duma"
          style="width: 19rem"
        />

        <G4BButton
          text="néha jól jön ha kitölti a helyet"
          :fill="true"
        />

        <G4BButton text="Disabled" disabled />

        <!-- END:BUTTON DEFAULT -->

        <!-- START:ICON ONLY BUTTONS DEFAULT -->
        <h4>Icon Only Buttons Default</h4>
        <i class="Icon IconNotification"></i>
        <G4BButton
          text="Felirat"
          type="defaultIconOnlyButton"
        >
          <i class="Icon IconNotification"></i>
        </G4BButton>
        <G4BButton
          text="Felirat"
          type="defaultIconOnlyButton"
        >
          <i class="FontIcon Icon-MenuAvatars" />
        </G4BButton>
        <G4BButton
          text="Felirat"
          type="defaultIconOnlyButton"
          disabled
        >
          <i class="FontIcon Icon-MenuAvatars" />
        </G4BButton>
        <!-- END:ICON ONLY BUTTON DEFAULT -->

        <!-- START:ICON ONLY BUTTONS -->
        <h4>Icon Only Buttons</h4>
        <G4BButton
          text="Felirat"
          type="iconOnlyButton"
        >
          <i class="FontIcon Icon-ArrowLeft" />
        </G4BButton>
        <G4BButton
          text="Felirat"
          type="iconOnlyButton"
          disabled
        >
          <i class="FontIcon Icon-ArrowLeft" />
        </G4BButton>
        <!-- END:ICON ONLY BUTTON -->

        <!-- START:ICON ONLY BUTTONS INVERSE-->
        <h4>Icon Only Buttons Inverse</h4>
        <G4BButton
          text="Felirat"
          type="iconOnlyButtonInverse"
        >
          <i class="FontIcon Icon-MenuAvatars" />
        </G4BButton>
        <G4BButton
          text="Felirat"
          type="iconOnlyButtonInverse"
          disabled
        >
          <i class="FontIcon Icon-MenuAvatars" />
        </G4BButton>
        <!-- END:ICON ONLY BUTTON INVERSE-->

        <!-- START:CIRCLED ICON ONLY BUTTONS -->
        <h4>Circled Icon Only Buttons</h4>
        <G4BButton
          text="Felirat"
          type="cicledIconOnlyButton"
        >
          <!-- prettier-ignore -->
          <img
            style="width:100%;"
            src="@/assets/image/Theme/image/notification.png"
          >
        </G4BButton>
        <G4BButton
          text="Felirat"
          type="cicledIconOnlyButton"
          disabled
        >
          <!-- prettier-ignore -->
          <img
            style="width:100%;"
            src="@/assets/image/Theme/image/notification.png"
          >
        </G4BButton>

        <!-- START:CIRCLED ICON ONLY BUTTONS -->
        <h4>Circled Icon Only Buttons Small</h4>
        <G4BButton
          text="Felirat"
          type="smallCircledIconOnlyButton"
        >
          <!-- prettier-ignore -->
          <img
            style="width:100%;"
            src="@/assets/image/Theme/image/notification.png"
          >
        </G4BButton>
        <G4BButton
          text="Felirat"
          type="smallCircledIconOnlyButton"
          disabled
        >
          <!-- prettier-ignore -->
          <img
            style="width:100%;"
            src="@/assets/image/Theme/image/notification.png"
          >
        </G4BButton>
        <!-- END:CIRCLED ICON ONLY BUTTONS -->
      </ComponentItem>
      <ComponentItem
        title="Cards"
        style="max-width: 30rem"
      >
        <!-- START:Cards 2 -->
        <h4>Cards</h4>

        <!-- START:PRINTING CARDS -->
        <Card
          v-for="(
            cardInstance, index
          ) in cardInstances"
          :key="index"
          :bgColor="cardInstance.bgColor"
          :title="cardInstance.title"
          :titleMaxLines="
            cardInstance.titleMaxLines
          "
          :subtitleMaxLines="
            cardInstance.subtitleMaxLines
          "
          :subtitle="cardInstance.subtitle"
          :action="cardInstance.action"
          :progressBar="cardInstance.progressBar"
          :thumbnail="cardInstance.thumbnail"
          :circledThumbnail="
            cardInstance.circledThumbnail
          "
          :state="cardInstance.state"
          :isHighlighted="
            cardInstance.isHighlighted
          "
        >
          <template v-slot:action>
            <i class="FontIcon"></i>
          </template>
        </Card>
        <!-- END:PRINTING CARDS -->
      </ComponentItem>

      <ComponentItem title="Modal">
        <div>
          <Modal ref="sampleModal1">
            <template v-slot:header>
              <h1>Modal title</h1>
            </template>

            <template v-slot:body>
              <p>
                Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut
                labore et dolore magna aliqua.
                Nunc sed velit dignissim sodales
                ut eu sem integer vitae. Id
                aliquet lectus proin nibh nisl
                condimentum. Fringilla urna
                porttitor rhoncus dolor purus. Nam
                aliquam sem et tortor. Nisl vel
                pretium lectus quam id. Cras
                pulvinar mattis nunc sed. Quis
                ipsum suspendisse ultrices gravida
                dictum fusce ut placerat orci.
                Tristique magna sit amet purus.
                Fermentum et sollicitudin ac orci
                phasellus egestas tellus. Erat
                pellentesque adipiscing commodo
                elit at imperdiet dui accumsan.
                Felis eget nunc lobortis mattis
                aliquam faucibus. Tincidunt eget
                nullam non nisi est sit amet
                facilisis. Mi in nulla posuere
                sollicitudin aliquam ultrices
                sagittis orci. Vitae proin
                sagittis nisl rhoncus mattis
                rhoncus urna neque. Eget nunc
                scelerisque viverra mauris in
                aliquam sem fringilla ut. Nec nam
                aliquam sem et tortor consequat
                id. Commodo nulla facilisi nullam
                vehicula ipsum a. Elementum tempus
                egestas sed sed. Faucibus purus in
                massa tempor nec feugiat nisl
                pretium fusce.
              </p>
              <p>
                Arcu cursus vitae congue mauris
                rhoncus aenean. Tempor id eu nisl
                nunc mi. Pharetra diam sit amet
                nisl suscipit adipiscing bibendum.
                Ut faucibus pulvinar elementum
                integer enim. Odio facilisis
                mauris sit amet massa vitae tortor
                condimentum lacinia. Eu non diam
                phasellus vestibulum lorem sed
                risus. Porttitor rhoncus dolor
                purus non enim praesent. Sit amet
                mauris commodo quis imperdiet.
                Lobortis feugiat vivamus at augue
                eget. Nibh tellus molestie nunc
                non blandit. Tellus mauris a diam
                maecenas sed enim ut. Tortor
                aliquam nulla facilisi cras
                fermentum odio eu feugiat pretium.
                Mattis aliquam faucibus purus in
                massa.
              </p>
            </template>

            <template v-slot:footer>
              <div>
                <button
                  @click="
                    $refs.sampleModal1.closeModal()
                  "
                >
                  Cancel
                </button>
                <button
                  @click="
                    $refs.sampleModal1.closeModal()
                  "
                >
                  Save
                </button>
              </div>
            </template>
          </Modal>
          <G4BButton
            @click="
              $refs.sampleModal1.openModal()
            "
            text="open basic modal"
          ></G4BButton>
        </div>
        <div>
          <G4BModal ref="sampleModal2">
            <template v-slot:header>
              <h1>Modal title</h1>
            </template>

            <template v-slot:body>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 0.4rem;
                "
              >
                <Panel>
                  <p>
                    Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit,
                    sed do eiusmod tempor
                    incididunt ut labore et dolore
                    magna aliqua. Nunc sed velit
                    dignissim sodales ut eu sem
                    integer vitae. Id aliquet
                    lectus proin nibh nisl
                    condimentum. Fringilla urna
                    porttitor rhoncus dolor purus.
                    Nam aliquam sem et tortor.
                    Nisl vel pretium lectus quam
                    id. Cras pulvinar mattis nunc
                    sed. Quis ipsum suspendisse
                    ultrices gravida dictum fusce
                    ut placerat orci. Tristique
                    magna sit amet purus.
                    Fermentum et sollicitudin ac
                    orci phasellus egestas tellus.
                    Erat pellentesque adipiscing
                    commodo elit at imperdiet dui
                    accumsan. Felis eget nunc
                    lobortis mattis aliquam
                    faucibus. Tincidunt eget
                    nullam non nisi est sit amet
                    facilisis. Mi in nulla posuere
                    sollicitudin aliquam ultrices
                    sagittis orci. Vitae proin
                    sagittis nisl rhoncus mattis
                    rhoncus urna neque. Eget nunc
                    scelerisque viverra mauris in
                    aliquam sem fringilla ut. Nec
                    nam aliquam sem et tortor
                    consequat id. Commodo nulla
                    facilisi nullam vehicula ipsum
                    a. Elementum tempus egestas
                    sed sed. Faucibus purus in
                    massa tempor nec feugiat nisl
                    pretium fusce.
                  </p>
                </Panel>
                <Panel>
                  <p>
                    Arcu cursus vitae congue
                    mauris rhoncus aenean. Tempor
                    id eu nisl nunc mi. Pharetra
                    diam sit amet nisl suscipit
                    adipiscing bibendum. Ut
                    faucibus pulvinar elementum
                    integer enim. Odio facilisis
                    mauris sit amet massa vitae
                    tortor condimentum lacinia. Eu
                    non diam phasellus vestibulum
                    lorem sed risus. Porttitor
                    rhoncus dolor purus non enim
                    praesent. Sit amet mauris
                    commodo quis imperdiet.
                    Lobortis feugiat vivamus at
                    augue eget. Nibh tellus
                    molestie nunc non blandit.
                    Tellus mauris a diam maecenas
                    sed enim ut. Tortor aliquam
                    nulla facilisi cras fermentum
                    odio eu feugiat pretium.
                    Mattis aliquam faucibus purus
                    in massa.
                  </p>
                </Panel>
              </div>
            </template>

            <template v-slot:footer>
              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  gap: 1rem;
                  align-items: center;
                  justify-content: center;
                "
              >
                <G4BButton
                  text="Cancel"
                  @click="
                    $refs.sampleModal2.closeModal()
                  "
                >
                  Cancel
                </G4BButton>
                <G4BButton
                  text="Save"
                  @click="
                    $refs.sampleModal2.closeModal()
                  "
                >
                  Save
                </G4BButton>
              </div>
            </template>
          </G4BModal>
          <G4BButton
            @click="
              $refs.sampleModal2.openModal()
            "
            text="open g4bmodal with slots only"
          ></G4BButton>
        </div>

        <!-- ROTATEABLE MODAL -->
        <div>
          <G4BModal
            ref="rotateableModal1"
            title="MODAL"
            subtitle="With Rotateable body"
          >
            <template v-slot:body>
              <Rotateable>
                <template v-slot:front>
                  <Panel>
                    <div>
                      Lorem Ipsum is simply dummy
                      text of the printing and
                      typesetting industry. Lorem
                      Ipsum has been the
                      industry's standard dummy
                      text ever since the 1500s
                    </div>
                  </Panel>
                </template>
                <template v-slot:back>
                  <Panel>
                    <div
                      style="margin-bottom: 3rem"
                    >
                      Lorem Ipsum is simply dummy
                      text of the printing and
                      typesetting industry. Lorem
                      Ipsum has been the
                      industry's standard dummy
                      text ever since the 1500s
                    </div>
                    <Card
                      bgColor="#797ef6"
                      title="Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
                      subtitle="Lorem Ipsum is simply dummy text of
              the printing and typesetting
              industry. Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
                      :isHighlighted="true"
                    ></Card>
                  </Panel>
                </template>
              </Rotateable>
            </template>

            <template v-slot:footer>
              <div>valami footer</div>
              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  gap: 0.8rem;
                  align-items: center;
                  justify-content: center;
                "
              >
                <G4BButton
                  text="Cancel"
                  @click="
                    $refs.rotateableModal1.closeModal()
                  "
                >
                </G4BButton>
                <G4BButton
                  text="Save"
                  @click="
                    $refs.rotateableModal1.closeModal()
                  "
                >
                </G4BButton>
              </div>
            </template>
          </G4BModal>
          <G4BButton
            @click="
              $refs[
                'rotateableModal1'
              ].openModal()
            "
            text="Modal with rotateable body"
          ></G4BButton>
        </div>
        <!-- ROTATEABLE MODAL -->

        <!-- INJECTABLE MODAL -->
        <div>
          <G4BModal
            ref="injectableModal1"
            title="Injectable modal"
          >
            <template v-slot:body>
              <Panel>
                <div>
                  Lorem Ipsum is simply dummy text
                  of the printing and typesetting
                  industry. Lorem Ipsum has been
                  the industry's standard dummy
                  text ever since the 1500s

                  <G4BButton
                    text="Open innner modal"
                    @click="
                      $refs.innerModal.openModal()
                    "
                  >
                  </G4BButton>
                </div>

                <G4BModal
                  ref="innerModal"
                  title="Inner modal"
                >
                  <template v-slot:body>
                    <Panel>
                      <div>Belső modal</div>
                      <G4BButton
                        text="Open innner modal2"
                        @click="
                          $refs.innerModal2.openModal()
                        "
                      >
                      </G4BButton>
                      <G4BModal
                        ref="innerModal2"
                        title="Inner modal"
                      >
                        <template v-slot:body>
                          <Panel>
                            <div>
                              Belső modal 2
                            </div>
                          </Panel>
                        </template>
                      </G4BModal>
                    </Panel>
                  </template>
                </G4BModal>
              </Panel>
            </template>

            <template v-slot:footer>
              <div>valami footer</div>
              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  gap: 0.8rem;
                  align-items: center;
                  justify-content: center;
                "
              >
                <G4BButton
                  text="Cancel"
                  @click="
                    $refs.injectableModal1.closeModal()
                  "
                >
                </G4BButton>
                <G4BButton
                  text="Save"
                  @click="
                    $refs.injectableModal1.closeModal()
                  "
                >
                </G4BButton>
              </div>
            </template>
          </G4BModal>
          <G4BButton
            @click="
              $refs[
                'injectableModal1'
              ].openModal()
            "
            text="Injectable modal"
          ></G4BButton>
        </div>
        <!-- INJECTABLE MODAL -->
      </ComponentItem>
      <!-- START:ROTATEABLE:7 -->
      <ComponentItem
        title="Rotateable"
        class="rotateable-examples"
        style="width: 20%"
      >
        <Rotateable>
          <template v-slot:front>
            <div
              class="rotateable-thing--1"
              style="width: 200px"
            >
              Lorem Ipsum is simply dummy text of
              the printing and typesetting
              industry. Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s
            </div>
          </template>
          <template v-slot:back>
            <div
              class="rotateable-thing--1"
              style="width: 350px"
            >
              Lorem Ipsum is simply dummy text of
              the printing and typesetting
              industry. Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500sLorem Ipsum is simply
              dummy text of the printing and
              typesetting industry. Lorem Ipsum
              has been the industry's standard
              dummy text ever since the 1500sLorem
              Ipsum is simply dummy text of the
              printing and typesetting industry.
              Lorem Ipsum has been the industry's
              standard dummy text ever since the
              1500s
            </div>
          </template>
        </Rotateable>

        <Rotateable>
          <template v-slot:front>
            <div
              class="rotateable-thing--1"
              style="width: 200px"
            >
              Lorem Ipsum is simply dummy text of
              the printing and typesetting
              industry. Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s
            </div>
          </template>
          <template v-slot:back>
            <Card
              style="width: 300px"
              bgColor="#797ef6"
              title="Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
              subtitle="Lorem Ipsum is simply dummy text of
              the printing and typesetting
              industry. Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
              :isHighlighted="true"
            ></Card>
          </template>
        </Rotateable>

        <Rotateable>
          <template v-slot:front>
            <Card title="Lorem"
              >Lorem Ipsum is simply dummy text of
              the printing and typesetting
              industry. Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s</Card
            >
          </template>
          <template v-slot:back>
            <Card
              style="width: 300px"
              bgColor="#797ef6"
              title="Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
              subtitle="Lorem Ipsum is simply dummy text of
              the printing and typesetting
              industry. Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
              :isHighlighted="true"
            ></Card>
          </template>
        </Rotateable>
      </ComponentItem>
      <!-- END:ROTATEABLE:7 -->

      <!-- START:TAB -->
      <ComponentItem
        title="Tab"
        style="width: 50%"
      >
        <!-- START:4ft EXAMPLE -->
        <Tabs class="wrapper-example">
          <Tab>
            <template v-slot:header>
              <i
                class="FontIcon Icon-MenuAvatars"
              />
              first tab
            </template>
            <template v-slot:content>
              1
            </template>
          </Tab>
          <Tab :disabled="true">
            <template v-slot:header>
              <i
                class="FontIcon Icon-MenuAvatars"
              />
              second tab
            </template>
            <template v-slot:content>
              2
            </template>
          </Tab>
          <Tab>
            <template v-slot:header>
              <!-- prettier-ignore -->
              <img
            src="@/assets/image/Theme/image/notification.png"
          >
            </template>
            <template v-slot:content>
              <div class="card-wrapper">
                <div class="card-item">
                  <Card
                    bgColor="#797ef6"
                    title="Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
                    subtitle="Lorem Ipsum is simply dummy text of
              the printing and typesetting
              industry. Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
                  ></Card>
                </div>
                <div class="card-item">
                  <Card
                    bgColor="#797ef6"
                    title="Lorem Ipsum has been the
              industry's standard dummy text ever
              since the 1500s"
                  ></Card>
                </div>
                <div class="card-item">
                  <Card
                    bgColor="#797ef6"
                    title="Lorem Ipsum has been the"
                  ></Card>
                </div>
              </div>
            </template>
          </Tab>
          <Tab :disabled="true">
            <template v-slot:header>
              <!-- prettier-ignore -->
              <img
            src="@/assets/image/Theme/image/notification.png"
          >
            </template>
            <template v-slot:content>4 </template>
          </Tab>
          <Tab :isNew="true">
            <template v-slot:header>
              <!-- prettier-ignore -->
              <img
            src="@/assets/image/Theme/image/notification.png"
          >
            </template>
            <template v-slot:content>4 </template>
          </Tab>
          <Tab :isNew="true">
            <template v-slot:header>
              Ez is új
            </template>
            <template v-slot:content
              >ez is új
            </template>
          </Tab>
          <Tab :isNew="true" :disabled="true">
            <template v-slot:header>
              <!-- prettier-ignore -->
              <img
            src="@/assets/image/Theme/image/notification.png"
          >
            </template>
            <template v-slot:content>4 </template>
          </Tab>
          <Tab :isNew="true" :disabled="true">
            <template v-slot:header>
              Ez is új
            </template>
            <template v-slot:content
              >ez is új
            </template>
          </Tab>
        </Tabs>
        <!-- END:4ft EXAMPLE -->
        <!-- START:5th EXAMPLE -->
        <Tabs class="wrapper-example">
          <Tab>
            <template v-slot:header>
              <i
                class="FontIcon Icon-MenuAvatars"
              />
              first tab fdsfdsfsd
            </template>
            <template v-slot:content>
              1
            </template>
          </Tab>
          <Tab>
            <template v-slot:header>
              <i
                class="FontIcon Icon-MenuAvatars"
              />
              second tab
            </template>
            <template v-slot:content>
              2
            </template>
          </Tab>

          <Tab :isNew="true">
            <template v-slot:header>
              <!-- prettier-ignore -->
              <img
            src="@/assets/image/Theme/image/notification.png"
          >
            </template>
            <template v-slot:content>4 </template>
          </Tab>
          <Tab :isNew="true" :disabled="true">
            <template v-slot:header>
              Ez is új
            </template>
            <template v-slot:content
              >ez is új
            </template>
          </Tab>
        </Tabs>
        <!-- END:5th EXAMPLE -->
        <!-- START:6th EXAMPLE -->
        <Tabs class="wrapper-example">
          <Tab>
            <template v-slot:header>
              <i
                class="FontIcon Icon-MenuAvatars"
              />
              first tab
            </template>
            <template v-slot:content>
              1
            </template>
          </Tab>
        </Tabs>
        <!-- END:6th EXAMPLE -->
      </ComponentItem>
      <!-- END:TAB -->
    </div>
  </div>
</template>

<script>
import { defineComponent, createApp } from "vue";
// custom components
import G4BButton from "@/components/g4b/button/Button.vue";
import Card from "@/components/g4b/card/Card.vue";
import {
  defaultProgressBarConfig,
  customProgressBarConfig,
} from "@/components/g4b/card/Card";
import Rotateable from "@/components/g4b/rotateable/Rotateable.vue";
import Modal from "@/components/modal/Modal.vue";
import G4BModal from "@/components/g4b/g4bmodal/G4BModal.vue";
import Panel from "@/components/g4b/panel/Panel.vue";
import Tabs from "@/components/g4b/g4btabs/Tabs.vue";
import Tab from "@/components/g4b/g4btabs/tab/Tab.vue";
// import images
// import imgGameController from "@/assets/image/recommendationc/minigame.png";

export default {
  name: "ComponentsList",
  components: {
    G4BButton,
    Card,
    Rotateable,
    Modal,
    G4BModal,
    Panel,
    Tabs,
    Tab,
  },
  data() {
    return {
      // card instances
      cardInstances: [
        {
          title:
            "Title and thumbnail with custom color dsfdsf dsf sdf sdf sdfs sdf sd dsf sdfsd sf",
          bgColor: "#8e24aa",
          slots: {
            action: `<i class="FontIcon"></i>`,
          },
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Title and thumbnail",
          slots: {
            action: `<i class="FontIcon"></i>`,
          },
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Title and thumbnail but locked",
          slots: {
            action: `<i class="FontIcon"></i>`,
          },
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
          state: "locked",
        },
        {
          title: "Title and some",
          action: "action",
          slots: {
            action: `<i class="FontIcon"></i>`,
          },
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Title and some",
          action: "action",
          slots: {
            action: `<i class="FontIcon"></i>`,
          },
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
          isHighlighted: true,
        },
        {
          title:
            "Itt még van elvégezhető feladat",
          action: "Megnézem",
          slots: {
            action: `<i class="FontIcon"></i>`,
          },
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "when you have just a title",
          slots: {
            action: `<i class="FontIcon"></i>`,
          },
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "When you have title",
          subtitle: "And a Subtitle",
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Progressbar",
          subtitle:
            "With default progressbar config",
          progressBar:
            new defaultProgressBarConfig(),
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Progressbar",
          subtitle:
            "With custom length 3 steps 1 completed",
          progressBar:
            new customProgressBarConfig(3, 1),
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Progressbar",
          subtitle:
            "With custom length 7 steps 5 completed",
          progressBar:
            new customProgressBarConfig(7, 5),
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Progressbar",
          subtitle:
            "With custom length 10 steps 10 completed",
          progressBar:
            new customProgressBarConfig(10, 10),
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Progressbar without subtitle",
          progressBar:
            new customProgressBarConfig(10, 10),
          thumbnail: require("@/assets/image/recommendation/minigame.png"),
          circledThumbnail: false,
        },
        {
          title: "Title and some",
          action: "action",
          thumbnail: require("@/assets/image/custom-7.png"),
          circledThumbnail: true,
        },
        {
          title: "Title and some",
          action: "action",
          state: "locked",
          thumbnail: require("@/assets/image/custom-7.png"),
          circledThumbnail: true,
        },

        {
          title: "Title and progresssbar",
          progressBar:
            new customProgressBarConfig(10, 8),
          state: "locked",
          thumbnail: require("@/assets/image/custom-7.png"),
          circledThumbnail: true,
        },
        {
          title: "Title and progresssbar",
          progressBar:
            new customProgressBarConfig(10, 8),
          thumbnail: require("@/assets/image/custom-7.png"),
          circledThumbnail: true,
          state: "inactive",
        },
        {
          title: "Title and progresssbar",
          progressBar:
            new customProgressBarConfig(10, 8),
          thumbnail: require("@/assets/image/custom-7.png"),
          circledThumbnail: true,
          state: "completed",
        },
        {
          title:
            "AndrisTeszt miki teszt miki teszt miki teszt miki teszt miki tes",
          subtitle:
            "miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt mi",
          progressBar:
            new customProgressBarConfig(10, 2),
          thumbnail: require("@/assets/image/custom-7.png"),
          circledThumbnail: true,
        },
        {
          title: "WITH CUSTOM NUMBER OF LINES",
          titleMaxLines: 1,
          subtitle:
            "FOR EXAMPLE MAX 1 LINE AS TITLE AND 3 LINE AS SUBTITLE, teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt mi",
          subtitleMaxLines: 3,
          progressBar:
            new customProgressBarConfig(10, 2),
          thumbnail: require("@/assets/image/custom-7.png"),
          circledThumbnail: true,
        },
        {
          title:
            "AndrisTeszt miki teszt miki teszt miki teszt miki teszt miki tes",
          subtitle:
            "miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt mi",
          progressBar:
            new customProgressBarConfig(10, 2),
          state: "locked",
          thumbnail: require("@/assets/image/custom-7.png"),
          circledThumbnail: true,
        },
      ],
      rotateableCardInstances: [
        {
          front: {
            title: "Title and some",
            slots: {
              action: `<i class="FontIcon"></i>`,
            },
            thumbnail: require("@/assets/image/recommendation/minigame.png"),
            circledThumbnail: false,
          },
          back: {
            title: "Title and some",
            slots: {
              action: `<i class="FontIcon"></i>`,
            },
            thumbnail: require("@/assets/image/recommendation/minigame.png"),
            circledThumbnail: false,
          },
        },
        {
          front: {
            title: "Title and some",
            slots: {
              action: `<i class="FontIcon"></i>`,
            },
            thumbnail: require("@/assets/image/recommendation/minigame.png"),
            circledThumbnail: false,
          },
          back: {
            title: "Title and some",
            slots: {
              action: `<i class="FontIcon"></i>`,
            },
            thumbnail: require("@/assets/image/recommendation/minigame.png"),
            circledThumbnail: false,
          },
        },
        {
          front: {
            title:
              "AndrisTeszt miki teszt miki teszt miki teszt miki teszt miki tes",
            subtitle:
              "miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt mi",
            progressBar:
              new customProgressBarConfig(10, 2),
            state: "locked",
            thumbnail: require("@/assets/image/custom-7.png"),
            circledThumbnail: true,
          },
          back: {
            title:
              "AndrisTeszt miki teszt miki teszt miki teszt miki teszt miki tes",
            subtitle:
              "miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt miki teszt mi",
            progressBar:
              new customProgressBarConfig(10, 2),
            state: "locked",
            thumbnail: require("@/assets/image/custom-7.png"),
            circledThumbnail: true,
          },
        },
        {
          front: {
            title: "Title and some",
            slots: {
              action: `<i class="FontIcon"></i>`,
            },
            thumbnail: require("@/assets/image/recommendation/minigame.png"),
            circledThumbnail: false,
          },
          back: {
            title: "Title and some",
            slots: {
              action: `<i class="FontIcon"></i>`,
            },
            thumbnail: require("@/assets/image/recommendation/minigame.png"),
            circledThumbnail: false,
          },
        },
      ],
    };
  },
  methods: {
    addDynamicButton() {
      var buttonInstance = defineComponent({
        extends: G4BButton,
      });

      const div = document.createElement("div");
      this.$refs.container.appendChild(div);

      console.log(buttonInstance);
      createApp(buttonInstance).mount(div);
    },
    addDynamicButton2() {
      const CompB = {
        extends: G4BButton,
      };

      console.log(CompB);
      this.$refs.container.appendChild(CompB.$el);
    },
  },
  mounted() {
    // this.addDynamicButton();
    // this.addDynamicButton2();
  },
};
</script>

<style lang="scss" scoped>
.component-list {
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px
    0px;
  box-sizing: border-box;
  padding: 10px 20px;
}

.content-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  flex-wrap: wrap;

  gap: 1rem;
}

.rotateable-examples {
  .rotateable-thing--1 {
    min-width: 1.8rem;
    min-height: 1.8rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 2.4rem;
  }
}

.wrapper-example {
  .card-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    .card-item {
      flex: 0 0 calc(50% - 0.5rem);
      display: flex;
      align-items: stretch;
    }
  }
}
</style>
