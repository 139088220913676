import styled from "vue3-styled-components";

const gridProps = {
  areaName: String,
  defaults: Object,
  breakPoints: Array,
};

const generateAreas = (mode, columns) => {
  return mode !== "show"
    ? columns.map(
        (column) =>
          `& > .grid-area-${column.name} {
              grid-area: ${column.name};
            }`
      )
    : columns.map(
        (column) =>
          `& > .grid-area-${column.name} {
            grid-area: unset;
          }`
      );
};

// Generate selector for hidden column
const generateColumnSelector = (columnName) =>
  `& > .grid-area-${columnName.name}`;

// Generate selector for hidden columns
const generateColumnsSelector = (columns) => {
  return columns.map((hiddenColumn) =>
    generateColumnSelector(hiddenColumn)
  );
};

// Set display property of a list of selectors
const generateColumnsCSSRule = (columns) => {
  /* hide unecessary columns */
  return `
  ${
    columns
      .slice()
      .filter((column) => column.hidden).length >
      0 &&
    generateColumnsSelector(
      columns
        .slice()
        .filter((column) => column.hidden)
    )
  }{
    display: none;
  }
  /* show necessary columns */
  ${generateColumnsSelector(
    columns
      .slice()
      .filter((column) => !column.hidden)
  )}{
    display: block;
  }
  `;
};

//// new
const generateArea = (mode, areaName) => {
  return `grid-area: ${
    mode !== "show" ? areaName : "unset"
  }`;
};

const generateDisplay = (mode, isHidden) => {
  return `display: ${
    isHidden && mode === "hide" ? "none" : "block"
  }`;
};

const StyledGridItem = styled("div", gridProps)`
  grid-area: ${(props) => props.areaName};
  overflow: hidden;
  /* grid-area: unset; */

  ${(props) =>
    generateArea(
      props.defaults.mode,
      props.areaName
    )}

  ${(props) =>
    generateDisplay(
      props.defaults.mode,
      props.defaults.columns.find(
        (item) => item.name === props.areaName
      ).hidden
    )}

  ${(props) =>
    props.breakPoints.map((breakpont) => {
      return `@media only screen and (min-width: ${
        breakpont.min
      }px) {
  
          ${generateArea(
            breakpont.mode,
            breakpont.template
          )}

          ${generateDisplay(
            breakpont.mode,
            breakpont.columns.find(
              (item) =>
                item.name === props.areaName
            ).hidden
          )}
  
        }`;
    })}
`;

export default StyledGridItem;
