<template>
  <div class="component-list">
    <div class="header-section">
      <h2>Swiper</h2>
    </div>
    <div class="content-section">
      <!-- START:DEFAULT SAMPLE -->
      <ComponentItem
        title="Default"
        style="width: 100%"
        contentStyle="flex-direction: row; flex-wrap:wrap;"
      >
        <swiper
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          class="mySwiper"
        >
          <swiper-slide>
            <div class="sample-item">1</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">2</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">3</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">4</div>
          </swiper-slide>
          <swiper-slide
            ><div class="sample-item">5</div>
          </swiper-slide>
          <swiper-slide
            ><div class="sample-item">6</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">7</div>
          </swiper-slide>
          <swiper-slide
            ><div class="sample-item">8</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">9</div>
          </swiper-slide>
          ...
        </swiper>
      </ComponentItem>
      <!-- END:DEFAULT SAMPLE -->
      <!-- START:NAVIGATION SAMPLE -->
      <ComponentItem
        title="Navigation"
        style="width: 100%"
        contentStyle="flex-direction: row; flex-wrap:wrap;"
      >
        <swiper
          :navigation="true"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          class="swiper-example--nav"
        >
          <swiper-slide>
            <div
              class="swiper-example--nav__sample-item"
            >
              1
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="swiper-example--nav__sample-item"
            >
              2
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="swiper-example--nav__sample-item"
            >
              3
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="swiper-example--nav__sample-item"
            >
              4
            </div>
          </swiper-slide>
          <swiper-slide
            ><div
              class="swiper-example--nav__sample-item"
            >
              5
            </div>
          </swiper-slide>
          <swiper-slide
            ><div
              class="swiper-example--nav__sample-item"
            >
              6
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="swiper-example--nav__sample-item"
            >
              7
            </div>
          </swiper-slide>
          <swiper-slide
            ><div
              class="swiper-example--nav__sample-item"
            >
              8
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="swiper-example--nav__sample-item"
            >
              9
            </div>
          </swiper-slide>
          ...
        </swiper>
      </ComponentItem>
      <!-- END:3 NAVIGATION SAMPLE -->
      <!-- START:3 SLIDE PER VIEW SAMPLE -->
      <ComponentItem
        title="3 SLIDE PER VIEW"
        style="width: 100%"
        contentStyle="flex-direction: row; flex-wrap:wrap;"
      >
        <swiper
          :slides-per-view="3"
          :space-between="50"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          class="mySwiper"
        >
          <swiper-slide>
            <div class="sample-item">1</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">2</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">3</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">4</div>
          </swiper-slide>
          <swiper-slide
            ><div class="sample-item">5</div>
          </swiper-slide>
          <swiper-slide
            ><div class="sample-item">6</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">7</div>
          </swiper-slide>
          <swiper-slide
            ><div class="sample-item">8</div>
          </swiper-slide>
          <swiper-slide>
            <div class="sample-item">9</div>
          </swiper-slide>
          ...
        </swiper>
      </ComponentItem>
      <!-- END:3 SLIDE PER VIEW SAMPLE -->
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// import navigation module for swiper
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// install Swiper modules
SwiperCore.use([Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      //console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>

<style lang="scss">
.component-list {
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px
    0px;
  box-sizing: border-box;
  padding: 10px 20px;
}

.content-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  flex-wrap: wrap;

  gap: 1rem;
}

/*** SWIPER  ***/
.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sample-item {
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: rgb(240, 240, 240);
  min-height: 300px;
  margin: 15px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
}
</style>

<style lang="scss">
.swiper-example--nav {
  width: 100%;
  height: 100%;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // custom divs
  &__sample-item {
    box-sizing: border-box;
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background: rgb(240, 240, 240);
    min-height: 300px;
    width: 100%;
  }
}
</style>
