import { IG4BTour, IStep } from "./types";
import SheperdAdapter from "./shepherd/Adapter";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $g4btour(options: any): IG4BTour;
  }
}

class G4BTour {
  options: any;
  tour: IG4BTour;

  // private properties
  private _steps: IStep[] = [];

  constructor({ tour }: { tour: IG4BTour }) {
    this.tour = tour;
  }

  start() {
    this.tour.start();
  }

  stop() {
    this.tour.stop();
  }

  addStep(step: IStep) {
    this._steps.push(step);
    this.tour.addStep(step);
  }

  moveToNextStep() {
    this.tour.moveToNextStep();
  }

  moveToPrevStep() {
    this.tour.moveToPrevStep();
  }

  get steps() {
    return this._steps.slice();
  }
}

const plugin = {
  install(app: any, options: any) {
    app.config.globalProperties.$g4btour = (
      options = {}
    ) => {
      return new G4BTour({
        tour: new SheperdAdapter(app, options),
      });
    };
    app.provide(
      "g4btour",
      app.config.globalProperties.$g4btour
    );
  },
};

export default plugin;
