<template>
  <p class="description">
    Ez egy nagyon rövid, 3 kérdésből álló, egy
    perces kvíz. Felkészültél?
  </p>
</template>

<style lang="scss" scoped>
.description {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", "Arial", sans-serif;
  color: #8bb6e5;
  line-height: 1.25;
  font-size: 1.2rem;
  height: 8rem;
  overflow: hidden;
  margin: 0;
}
</style>
