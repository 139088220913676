<script>
import Vue from "vue";
import {
  VTooltip,
  VPopover,
  VClosePopover,
} from "v-tooltip";

import "/node_modules/v-tooltip/dist/v-tooltip.css";

export default VTooltip;
</script>
