import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b504837"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-container" }
const _hoisted_2 = { class: "dropdown" }
const _hoisted_3 = { class: "dropdown__toggle-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown__menu-wrapper"
}
const _hoisted_5 = { class: "dropdown__menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "toggle", {}, undefined, true),
        (!_ctx.isSlotDropdownToggle)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "dropdown__toggle",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
            }, " dropdown button "))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isDropdownOpen)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "menu-items", {}, undefined, true)
            ])
          ])), [
            [_directive_click_away, _ctx.onClickAway]
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}