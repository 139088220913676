
/*
  #TODO create new tab compomenent with title and content slot to support custom titles with icons or only icons
  and parameters to allow render it or not
*/
import {
  ref,
  defineComponent,
  VNode,
  onMounted,
} from "vue";

export default defineComponent({
  setup(props, context) {
    const selectedTabIndex = ref(0);
    const tabs = ref<VNode[]>([]);

    // methods
    function selectTab(id: number) {
      selectedTabIndex.value = id;
    }

    // created lifecycle hook
    if (context.slots.default) {
      tabs.value = context.slots.default();
    }

    onMounted(() => {
      const firstActive = tabs.value.findIndex(
        (item) => !item.props?.disabled
      );
      if (firstActive !== -1) {
        selectedTabIndex.value = firstActive;
      }
    });

    return {
      selectedTabIndex,
      tabs,
      // methods
      selectTab,
    };
  },
});
