<template>
  <div>
    <h2>Start game dialog</h2>
    <G4BButton @click="modalBtnClickHandler">
      open
    </G4BButton>
    <G4BModal
      :ref="
        (el) => {
          modalRef = el;
        }
      "
      title="Pusztai Teszt Alapozó kérdések"
      subtitle="Multikvíz"
      :width="'calc(100% - 1.6rem)'"
      :maxWidth="'32rem'"
      headerColor="#eda95a"
    >
      >
      <template v-slot:body>
        <TabContent />
      </template>
      <template v-slot:footer>
        <RotateableFooter
      /></template>
    </G4BModal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import G4BModal from "@/components/g4b/g4bmodal/G4BModal.vue";
import G4BButton from "@/components/g4b/button/Button.vue";
// import modal contents
import TabContent from "./tab-content/TabContent.vue";
import RotateableFooter from "./rotateable-footer/RotateableFooter.vue";
const modalRef = ref();

const modalBtnClickHandler = (event) => {
  modalRef.value.openModal();
};
</script>
