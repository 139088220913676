
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const modalTitle = ref("Sample title");
    const show = ref(false);

    // methods
    function closeModal() {
      show.value = false;
      document!
        .querySelector("body")!
        .classList!.remove("overflow-hidden")!;
    }
    function openModal() {
      show.value = true;
      document!
        .querySelector("body")!
        .classList!.add("overflow-hidden")!;
    }

    return {
      modalTitle,
      show,
      closeModal,
      openModal,
    };
  },
});
