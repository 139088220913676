
// TODO use BEM syntax style
import {
  defineComponent,
  computed,
  PropType,
} from "vue";
import {
  CardStates,
  ProgressBarConfig,
} from "./Card";

export default defineComponent({
  name: "Card",
  props: {
    title: {
      type: String,
      required: true,
    },
    titleMaxLines: {
      type: Number,
      required: false,
      default: undefined,
    },
    subtitle: {
      type: String,
      required: false,
      default: undefined,
      // TODO módosítani minden default value-t undefined-re
    },
    subtitleMaxLines: {
      type: Number,
      required: false,
      default: undefined,
    },
    action: {
      type: String,
      required: false,
      default: undefined,
    },
    bgColor: {
      type: String,
      required: false,
    },
    progressBar: {
      type: Object as PropType<ProgressBarConfig> | null,
      required: false,
      default: undefined,
    },
    // thumbnail props
    circledThumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
    thumbnail: {
      type: String,
      required: false,
      default: undefined,
    },
    // state
    state: {
      type: String as
        | PropType<CardStates>
        | undefined,
      required: false,
      default: undefined,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const thumbnailClass = computed(() => {
      return {
        circled: props.circledThumbnail,
      };
    });

    const layoutClass = computed(() => {
      if (
        !props.subtitle &&
        !props.action &&
        !props.progressBar
      ) {
        return "layout-simple";
      } else if (
        (!props.subtitle ||
          props.subtitle.length == 0) &&
        props.action &&
        props.action.length > 0
      ) {
        return "layout-action";
      } else if (
        props.subtitle &&
        props.subtitle.length > 0 &&
        (!props.action ||
          props.action.length == 0) &&
        !props.progressBar
      ) {
        return "layout-subtitle";
      } else if (props.progressBar !== null) {
        return "layout-progressbar";
      }
      return null;
    });

    // statesHelpers
    const isLocked = computed(
      () => props.state === "locked"
    );

    const isInactive = computed(
      () => props.state === "inactive"
    );

    const isCompleted = computed(
      () => props.state === "completed"
    );

    const isLoading = computed(
      () => props.state === "loading"
    );

    const cardClass = computed(() => {
      return {
        locked: isLocked.value,
        inactive: isInactive.value,
        completed: isCompleted.value,
        loading: isLoading.value,
        highlighted: props.isHighlighted,
      };
    });

    const cssVariablesFromJS = computed(() => {
      return {
        ...(props.titleMaxLines && {
          "--card-title-maximum-number-of-lines":
            props.titleMaxLines,
        }),
        ...(props.subtitleMaxLines && {
          "--card-subtitle-maximum-number-of-lines":
            props.subtitleMaxLines,
        }),
      };
    });

    return {
      cardClass,
      cssVariablesFromJS,
      layoutClass,
      thumbnailClass,
      // state variables
      isLocked,
      isInactive,
      isCompleted,
    };
  },
});
