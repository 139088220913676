export type CardStates =
  | "locked"
  | "inactive"
  | "completed"
  | "loading";

// Proggres Bar Config
export interface ProgressBarConfig {
  steps: number;
  completedSteps: number;
}

export class defaultProgressBarConfig
  implements ProgressBarConfig
{
  steps: number;
  completedSteps: number;

  constructor() {
    this.steps = 5;
    this.completedSteps = 0;
  }
}

export class customProgressBarConfig extends defaultProgressBarConfig {
  constructor(
    steps: number,
    completedSteps: number
  ) {
    super();
    this.steps = steps;
    this.completedSteps =
      this.steps < completedSteps
        ? this.steps
        : completedSteps;
  }
}
