import { IG4BTour, IStep } from "../types";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $shepherd: any;
  }
}
import VueShepherdCustom from "./vue-shepherd-custom.js";

class vueShepherd implements IG4BTour {
  instance: any;

  constructor(app: any, options: any) {
    this.instance = this.install(app, options);
  }

  install(app: any, options: any) {
    app.use(VueShepherdCustom);
    return app.config.globalProperties.$shepherd(
      options
    );
  }

  start() {
    this.instance.start();
  }

  stop() {
    this.instance.stop();
  }

  addStep(step: IStep) {
    this.instance.addStep(step);
  }

  moveToNextStep() {
    this.instance.next();
  }

  moveToPrevStep() {
    this.instance.back();
  }
}

export default vueShepherd;
