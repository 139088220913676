import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e2edd8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tabs"
}
const _hoisted_2 = { class: "tabs__header" }
const _hoisted_3 = { class: "tabs__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tabs.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(tab), {
              key: index,
              showHeader: true,
              showContent: false,
              active: _ctx.selectedTabIndex === index,
              tabIndex: index,
              onTabChanged: _ctx.selectTab
            }, null, 8, ["active", "tabIndex", "onTabChanged"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabs[_ctx.selectedTabIndex]), {
            key: _ctx.selectedTabIndex,
            showHeader: false,
            showContent: true
          }))
        ])
      ]))
    : _createCommentVNode("", true)
}