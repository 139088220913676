
import {
  watchEffect,
  defineComponent,
  ref,
} from "vue";
import G4BButton from "@/components/g4b/button/Button.vue";

export default defineComponent({
  components: {
    G4BButton,
  },
  setup() {
    const rotated = ref(false);
    const showBack = ref(rotated.value);
    const rotateContainer = ref(null);

    function rotateCard() {
      const rotateableCardEl = (
        rotateContainer!.value! as HTMLElement
      ).querySelector<HTMLElement>(
        ".rotate-card"
      )!;

      const transitionDuration: number =
        parseFloat(
          window.getComputedStyle(
            rotateableCardEl
          )!.transitionDuration!
        ) * 1000;

      const easeTiming: number = Math.floor(
        transitionDuration * 0.293
      );

      rotated.value = !rotated.value;

      /*
      setTimeout(() => {
        let a = 0;
        for (
          let index = 0;
          index < 100000;
          index++
        ) {
          for (
            let index = 0;
            index < 10000;
            index++
          ) {
            a++;
          }
        }
      }, 0);
      */

      // későbbiekben a settimeoutot ki kell/lehet váltani
      setTimeout(() => {
        showBack.value = rotated.value;
        /*
        console.log(
          rotateContainer.value!.clientHeight!
        );
        */
      }, easeTiming);
      // 29% 2320
      // 0.293 the correct number
      // 29.5% 2360
    }

    watchEffect(() => {
      if (rotateContainer.value) {
        /*
        console.log(
          rotateContainer.value!.clientHeight!
        );
        */
      }
    });

    return {
      rotated,
      showBack,
      rotateContainer,
      // methods
      rotateCard,
    };
  },
});
