import styled from "vue3-styled-components";

const StyledContent = styled("div")`
  font-size: 1.2rem;
  font-family: "Roboto", "Arial", sans-serif;
  color: #8bb6e5;
  line-height: 1.25;
`;

export default StyledContent;
