// when grid mode is grid then necessary the use nested result as interface and component also,
// when grid mode is table then grid item is actialy a table cell
type gridMode = "grid" | "table";

interface gridSetting {
  columns: string;
  autoColumns?: string;
  rows?: string;
  autoRows?: string;
  mode: gridMode;
  name: string;
  templateMode: string;
  gridItemTemplate: string;
  gridAutoFlow?: string;
}

interface breakpontSize {
  width: number; // in pixels
  height?: number; // in pixels
}

interface breakpoint extends gridSetting {
  min: number;
  max?: breakpontSize;
}

interface Item {
  [key: string]: {
    label?: string;
    value: any;
  };
}

export interface IConfig {
  items: Item[];
  default: gridSetting;
  breakpoints: breakpoint[];
}

export class G4BResultCSSConverter {
  private config;

  constructor(config: IConfig) {
    this.config = config;
  }

  get breakPoints() {
    return {
      default: {
        columns:
          this.config.default.columns ?? "1fr",
        ...(this.config.default.rows && {
          rows: this.config.default.rows ?? "1fr",
        }),
        ...(this.config.default.autoColumns && {
          autoColumns:
            this.config.default.autoColumns,
        }),
        ...(this.config.default.autoRows && {
          autoRows: this.config.default.autoRows,
        }),
      },
      breakpoints: [
        ...this.config.breakpoints.map(
          (breakpoint) => {
            return {
              min: breakpoint.min,
              ...(breakpoint.max && {
                max: breakpoint.max ?? "1fr",
              }),
              columns:
                breakpoint.columns ?? "1fr",
              ...(breakpoint.rows && {
                rows: breakpoint.rows ?? "1fr",
              }),

              ...(breakpoint.autoColumns && {
                autoColumns:
                  breakpoint.autoColumns,
              }),

              ...(breakpoint.autoRows && {
                autoRows: breakpoint.autoRows,
              }),
            };
          }
        ),
      ],
    };
  }

  private getFields() {
    return (
      (this.config.items &&
        this.config.items[0] &&
        Object.keys(this.config.items[0])) ||
      null
    );
  }

  private calcHiddenColumns(
    templateMode: string,
    template: string
  ) {
    if (templateMode === "show") {
      return [];
    }
    const templateColumns = Array.from(
      new Set(
        template
          .replace(/(?:\r\n|\r|\n|[""])/g, "")
          .split(" ")
          .filter((n) => n)
      )
    );

    return this.getFields().filter(
      (x) => !templateColumns.includes(x)
    );
  }

  calcColumns(hiddenColumns: any[]) {
    const allColumn = this.getFields();

    return allColumn.map((column) => {
      return {
        name: column,
        hidden: hiddenColumns.includes(column),
      };
    });
  }

  get templates() {
    // todo has to add the visible parts of each template
    // :areas="Object.keys(result.items[0])"
    const defaultHiddenColumns =
      this.calcHiddenColumns(
        this.config.default.templateMode,
        this.config.default.gridItemTemplate
      );

    return {
      default: {
        ...(this.config.default.gridAutoFlow && {
          gridAutoFlow:
            this.config.default.gridAutoFlow,
        }),
        mode: this.config.default.templateMode,
        template:
          this.config.default.gridItemTemplate,
        columns: this.calcColumns(
          defaultHiddenColumns
        ),
      },
      breakpoints: [
        ...this.config.breakpoints.map(
          (breakpoint) => {
            const hiddenColumns =
              this.calcHiddenColumns(
                breakpoint.templateMode,
                breakpoint.gridItemTemplate
              );
            return {
              ...(breakpoint.gridAutoFlow && {
                gridAutoFlow:
                  breakpoint.gridAutoFlow,
              }),
              mode: breakpoint.templateMode,
              template:
                breakpoint.gridItemTemplate,
              columns: this.calcColumns(
                hiddenColumns
              ),
              min: breakpoint.min,
            };
          }
        ),
      ],
    };
  }

  get items() {
    return this.config.items;
  }
}
