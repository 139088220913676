import styled from "vue3-styled-components";

const gridProps = {
  defaults: Object,
  breakPoints: Array,
};

const StyledOuterGrid = styled("div", gridProps)`
  display: grid;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  grid-template-columns: ${(props) =>
    props.defaults.columns};
  ${(props) => {
    return (
      props.defaults.rows &&
      `grid-template-rows:${props.defaults.rows};`
    );
  }}

  ${(props) => {
    return (
      props.defaults.autoColumns &&
      `grid-auto-columns:${props.defaults.autoColumns};`
    );
  }}
  ${(props) => {
    return (
      props.defaults.autoRows &&
      `grid-auto-rows:${props.defaults.autoRows};`
    );
  }}

  ${(props) =>
    props.breakPoints.map((breakpont) => {
      return `@media only screen and (min-width: ${
        breakpont.min
      }px) {
        grid-template-columns: ${
          breakpont.columns
        };
        ${
          (breakpont.rows &&
            `grid-template-rows:${breakpont.rows};`) ||
          `grid-template-rows: unset;`
        }

        ${
          (breakpont.autoColumns &&
            `grid-auto-columns:${breakpont.autoColumns};`) ||
          `grid-auto-columns: unset;`
        }
        ${
          (breakpont.autoRows &&
            `grid-auto-rows:${breakpont.autoRows};`) ||
          `grid-auto-rows: unset;`
        }
      }`;
    })}
`;

export default StyledOuterGrid;
