import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cfaf922"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-container" }
const _hoisted_2 = {
  class: "dropdown__menu__scroller",
  ref: "refDropdownScroller"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "dropdown",
      style: _normalizeStyle(_ctx.cssVariablesFromJS)
    }, [
      _createElementVNode("div", {
        ref: "refToggleWrapper",
        class: _normalizeClass(["dropdown__toggle-wrapper", _ctx.classToggleWrapper])
      }, [
        _renderSlot(_ctx.$slots, "toggle", {}, undefined, true)
      ], 2),
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["dropdown__menu", 
          'dropdown__menu--' +
          _ctx.classDropdownDirection
        ])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "menuItems", {}, undefined, true)
        ], 512)
      ], 2), [
        [_vShow, _ctx.isDropdownOpen]
      ])
    ], 4)), [
      [_directive_click_away, _ctx.onClickAway]
    ])
  ]))
}