import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import Home from "../views/Home.vue";
import SimpleGame from "../views/SimpleGame/SimpleGame.vue";

import Components from "../views/Components/Index.vue";
// components sub-routes samples
import ComponentsIndex from "../views/Components/List.vue";
import Dropdown from "../views/Components/Dropdown.vue";
import Carousels from "../views/Components/Carousels/Carousels.vue";
import Modals from "../views/Components/Modals/Modals.vue";
import Swiper from "../views/Components/Carousels/Swiper.vue";
import VueFlicking from "../views/Components/Carousels/VueFlicking.vue";
import G4BResult from "../views/Components/Result/Result.vue";
import G4BMultiselects from "../views/Components/Multiselects/Index.vue";
import G4BTooltip from "../views/Components/Tooltip/Tooltip.vue";
// Dialogs
import Dialogs from "../views/Components/Dialogs/Index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/About.vue"
      ),
  },
  {
    path: "/components/",
    component: Components,
    children: [
      {
        path: "",
        name: "ComponentsList",
        component: ComponentsIndex,
      },
      {
        path: "dropdown",
        name: "Dropdown",
        component: Dropdown,
      },
      {
        path: "modals",
        name: "Modals",
        component: Modals,
      },
      {
        path: "carousels",
        name: "Carousels",
        component: Carousels,
        children: [
          {
            path: "swiper",
            name: "Swiper",
            component: Swiper,
          },
          {
            path: "egjs_vue-flicking",
            name: "EGJSVueFlicking",
            component: VueFlicking,
          },
        ],
      },
      {
        path: "g4b-result",
        name: "G4BResult",
        component: G4BResult,
      },
      {
        path: "g4b-tooltip",
        name: "G4BTooltip",
        component: G4BTooltip,
      },
      {
        path: "dialogs",
        name: "Dialogs",
        component: Dialogs,
      },
    ],
  },
  {
    path: "/multiselects",
    name: "G4BMultiselects",
    component: G4BMultiselects,
  },
  {
    path: "/simple-game",
    name: "SimpleGame",
    component: SimpleGame,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
