
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    showContent: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ["tabChanged"],
  setup(props, { emit }) {
    const tabChanged = ref(true);
    function selectTab(index: number) {
      if (props.disabled === false) {
        emit("tabChanged", index);
      }
    }

    function showTooltip(event: any) {
      //console.log(typeof event);
      //console.log(event);
      //console.log(event!.toElement!.innerText!);
    }
    return {
      tabChanged,
      selectTab,
      showTooltip,
    };
  },
});
