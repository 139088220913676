import styled from "vue3-styled-components";

const StyledTitle = styled("div")`
  line-height: 1.25;
  font-size: 1.2rem;
  color: #fff;
  font-weight: bold;
`;

export default StyledTitle;
