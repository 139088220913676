import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fdd6c64c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "subtitle"
}
const _hoisted_5 = {
  key: 1,
  class: "action"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "state-container" }
const _hoisted_8 = {
  key: 0,
  class: "state-locked"
}
const _hoisted_9 = {
  key: 1,
  class: "state-inactive"
}
const _hoisted_10 = {
  key: 2,
  class: "state-completed"
}
const _hoisted_11 = { class: "card-footer" }
const _hoisted_12 = {
  key: 0,
  class: "progression"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card-wrapper",
    style: _normalizeStyle(_ctx.cssVariablesFromJS)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["card", _ctx.cardClass]),
      style: _normalizeStyle({ 'background-color': _ctx.bgColor })
    }, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["card-layout", _ctx.layoutClass])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
              (_ctx.subtitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.subtitle), 1))
                : _createCommentVNode("", true),
              (_ctx.action)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.action), 1),
                    _renderSlot(_ctx.$slots, "action", {}, undefined, true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.thumbnail)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["thumbnail", _ctx.thumbnailClass])
                }, [
                  _createElementVNode("img", {
                    src: _ctx.thumbnail,
                    alt: ""
                  }, null, 8, _hoisted_6),
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.isLocked)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                      : _createCommentVNode("", true),
                    (_ctx.isInactive)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                      : _createCommentVNode("", true),
                    (_ctx.isCompleted)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                      : _createCommentVNode("", true)
                  ])
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.progressBar)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progressBar.steps, (index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: _normalizeClass({
                    active:
                      index <=
                      _ctx.progressBar.completedSteps,
                  })
                      }, null, 2))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "custom-footer", {}, undefined, true)
          ])
        ], 2)
      ])
    ], 6)
  ], 4))
}