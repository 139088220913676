
import { defineComponent } from "vue";

const __default__ = defineComponent({
  props: {
    bgColor: {
      type: String,
      required: false,
      default: "#92c1d4",
    },
    imageURL: {
      type: String,
      required: true,
      default:
        "https://newdesign.g4bdev.eu/file/object/image-498-1-normal.png",
    },
    alt: {
      type: String,
      required: false,
      default: "Some placeholder text",
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4134a1b4": (_ctx.bgColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__